import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Switch from "@mui/material/Switch";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//JWT
import jwtDecode from "jwt-decode";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";


import { useAuth } from "../../../auth";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RaiseTicketModal = () => {
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectIssue, setSelectIssue] = useState("10");
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticketSubjectList, setTicketSubjectList] = useState([]);

  const [ticketEmail, setTicketEmail] = useState("");
  const [ticketSubject, setTicketSubject] = useState();
  const [ticketMessage, setTicketMessage] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isFileLoading, setIsFileLoading] = useState(null)

  const [isTicketSent, setIsTicketSent] = useState(null)

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };
  const userID = JSON.parse(localStorage.getItem("profile")).UserId;
  const { currentUser, userType, logout } = useAuth();

  useEffect(() => {
    const payload = {
      user_id: userID,
    };

    const getSubjectsList = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/ticket-subjects`,
          {
            method: "GET",
            headers,
          }
        );
        const postResponse = await response.json();

        const { subjects } = await postResponse.data;

        setTicketSubjectList(subjects);
        //setUserData(postResponse?.data);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getSubjectsList();
  }, []);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClientSelect = (event) => {
    setClient(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIssueChange = (e) => {
    setSelectIssue(e.target.value);
  };

  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    //console.log("file", file);
    setIsFileLoading(true)
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg" ||
      file?.type === "pdf"
    ) {
      let dirName = moment().format("MMMM_YYYY");
      //console.log("dirName", dirName);
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          // setPercent(percent);
        },
        (err) => enqueueSnackbar(err, { variant: "error" }),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // console.log("file path url ", url);
            //setLoading(false);
            setSelectedFiles(url);
            setIsFileLoading(false)
          });
        }
      );
    } else {
      console.error("Incorrect  file format.");
    }

    //setSelectedFiles(files);
  };

  //Ticket

  const handleTicketEmail = (e) => {
    setTicketEmail(e.target.value);

    let emailRegex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    let result = emailRegex.test(ticketEmail);

    if (!result) {
      setIsValidEmail(false)
    } else {
      setIsValidEmail(true)
    }
  };

  const handleTicketSubject = (e) => {
    setTicketSubject(e.target.value);
  };

  const handleTicketMessage = (e) => {
    setTicketMessage(e.target.value);
  };

  const handleSendTicket = async () => {

    setIsTicketSent(false)
    const payload = {
      user_id: userID,
      mail: currentUser?.Emailid,
      subject_id: ticketSubject,
      description: ticketMessage,
      file: selectedFiles,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/raise-ticket`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.code;

      if (message == 200) {
        setTicketEmail("");
        setTicketSubject();
        setTicketMessage("");
        setOpen(false);
        snackbarMessage(
          "Ticket Raised succesfully! You will be able to track it from here in a short while!"
        );
        setOpenSnackbar(true);
        setSelectedFiles([]);
        setIsTicketSent(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //console.log("selectedFiles", selectedFiles);

  //console.log("ticketSubjectList", ticketSubjectList);
  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        className="PrimaryCTAExp"
        disableElevation
      >
        <AddCircleIcon />
        Contact Support
      </Button>

      <Dialog
        className="AddApointment--Dialog"
        open={open}
        onClose={handleClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {
          (isTicketSent !== null && !isTicketSent) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        }

        <DialogTitle>Contact Support</DialogTitle>
        <p
          style={{
            padding: "0 24px",
            color: "#656565",
            fontSize: "16px",
            lineHeight: "130%",
          }}
        >
          Please raise your ticket here. Our support team will reach back to you
          soon.
        </p>
        <DialogContent className="Dialog--Content">
          {/* <TextField
            sx={{
              width: "100%",
            }}
            id="outlined-basic"
            label="Get updates on"
            value={ticketEmail}
            type={"email"}
            onChange={handleTicketEmail}
            variant="outlined"
          />
          {
            !isValidEmail && <span style={{ fontSize: '0.8rem', color: 'red' }}>Email is in wrong format!</span>
          } */}


          {/* <FormControl fullWidth sx={{ marginTop: "15px" }}>
            <TextField
              sx={{
                width: "100%",
              }}
              id="outlined-basic"
              label="Subject"
              value={ticketSubject}
              onChange={handleTicketSubject}
              variant="outlined"
            />
          </FormControl> */}
          <FormControl
            sx={{
              background: "#FFF",
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <InputLabel id="demo-simple-select-label" sx={{ background: "#FFF", padding: "0 5px" }}>Subject</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ticketSubject}
              onChange={handleTicketSubject}
              fullWidthticketSubjectList
            >
              {ticketSubjectList?.map((item, i) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="outlined-multiline-static"
            label="Explain in few words"
            multiline
            fullWidth
            rows={4}
            value={ticketMessage}
            onChange={handleTicketMessage}
            sx={{ marginTop: "15px" }}
          />

          <div className="upload-button">
            <label htmlFor="file-upload" className="upload-button-label">
              <FileUploadOutlinedIcon className="upload-button-icon" />
              Upload Files
            </label>
            {
              isFileLoading !== null && isFileLoading ? (
                <div className="d-flex align-items-center mx-auto">
                  <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} />
                </div>

              ) : (
                <>
                  <input
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="upload-button-input"
                  />
                  {selectedFiles && (
                    <div className="selected-files">
                      <div className="file-name">{selectedFiles}</div>
                    </div>
                  )}
                </>
              )
            }

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="PrimaryCTAExp"
            sx={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={handleSendTicket}
            variant="contained"
            disableElevation
            disabled={ticketMessage == ""}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RaiseTicketModal;
