import React from "react";
import { enqueueSnackbar } from "notistack";
import { usOnBoarding_registrationStatus } from "../core/_request";

const ThankYouTab = () => {
  window.onbeforeunload = function () {
    return "You might lose all the progress if you refresh. Are you sure?";
  };

  const handleGoToHomeClick = () => {
    const user_id =
      JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
      JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
    const body = { user_id: user_id };

    usOnBoarding_registrationStatus(body).then((resp) => {
      if (
        resp.code === 200 &&
        resp?.data?.profile === true &&
        resp?.data?.professional === true &&
        resp?.data?.scheduling === true &&
        resp?.data?.payment === true
      ) {
        sessionStorage.removeItem("Email_Verified_id");
        sessionStorage.removeItem("RegisterAs");
        sessionStorage.removeItem("UsOnBoarding");

        // Redirect to home page after removing session storage items
        window.open("https://www.unitedwecare.com");
      } else {
        enqueueSnackbar("Please Complete Your Profile.", {
          variant: "warning",
        });
      }
    });
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center gap-3 py-3 rounded-top px-3"
        style={{ background: "#DCD0EB" }}
      >
        <p
          className="font-inter font-w500 text-black"
          style={{ color: "#21231E", fontSize: "24px" }}
        >
          Thank you for your submission!
        </p>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center py-5">
        <img src="/assets/img/OBJECTS.png" alt="" />
        <p
          className="font-inter text-center py-5 px-3"
          style={{ color: "#454745", fontSize: "16px" }}
        >
          Your profile is currently under review by our team. We appreciate your
          patience during this process. You will receive a notification via
          email within 48 hours once the review is complete. We look forward to
          having you as a valued expert on our platform.
        </p>
        <div className="d-flex justify-content-center py-3">
          <button
            className="py-1 px-4 rounded-3 font-inter font-w500 text-white border"
            style={{
              background: "#73589B",
              textTransform: "none",
              fontSize: "20px",
              borderColor: "#73589B",
            }}
            onClick={handleGoToHomeClick}
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouTab;
