import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../../context";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Logo from "../../../dashboard/images/Logo.webp";
import logo from "../../images/Logo2.png";
import KariefyLogo from "../../images/UWC logo.png";
import { useAuth } from "../../../../auth";

import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { validate_token } from "../../../core/_request";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { handleBrokenImage } from "../../../../lib/utils";
import Cookies from "js-cookie";

const hexToHSL = (hex) => { };

function Navbar() {
  const { currentUser, logout, userType, login, setCurrentUser } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const cookie_token = Cookies.get("authToken");
  const authUserType = Cookies.get("authUserRole");

  const [isRegisterAsProfessional, setIsRegisterAsProfessional] =
    useState(false);

  const [primaryColor, setPrimaryColor] = useState();
  const [gradient, setGradient] = useState(
    process.env.REACT_APP_CLIENT_DISPLAY_COLOR || "#73589B"
  );

  useEffect(() => {
    const redirectionViaIp = () => {
      if (window.location.host.includes("ask-sam.ai")) {
        // const { hue, saturation, lightness } = hexToHSL("#1B409B");
        document.documentElement.style.setProperty("--primary", "#0C1C44 ");
        setPrimaryColor("#0C1C44 ");
        // setGradient('linear-gradient(135deg, #1B409B, #6A8DCF)');
        setGradient("linear-gradient(135deg, #0C1C44 , #4A7190)");
      } else {
        // const { hue, saturation, lightness } = hexToHSL("#73589B");
        document.documentElement.style.setProperty("--primary", "#73589B");
        setPrimaryColor("#73589B");
        setGradient("linear-gradient(135deg, #73589B, #4B0082)");
      }
    };

    redirectionViaIp();
  }, []);

  useEffect(() => {
    const root = document.documentElement; // Accessing the root element of the document
    root.style.setProperty("--primary-base-color", primaryColor);
    root.style.setProperty(
      "--primary-base-color-darken",
      darken(primaryColor, 20)
    );
    root.style.setProperty("--button-primary-color", `${primaryColor}`);
    root.style.setProperty("--secondary-gradient", `${gradient}`);
    root.style.setProperty("--primary-color", `${primaryColor}`);
    root.style.setProperty("--primary-accent-color", `${primaryColor}15`);
    root.style.setProperty("--primary-base-opacity30", `${primaryColor}30`);
    root.style.setProperty("--primary-base-opacity50", `${primaryColor}50`);
    root.style.setProperty("--secondary-color", darken(primaryColor, 10));
  }, [primaryColor]); // This effect runs whenever primaryColor changes

  // Helper functions to darken and fade colors
  function darken(color, percent) {
    const f = parseInt(color?.slice(1), 16),
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      "#" +
      (
        0x1000000 +
        Math.floor((R * (100 - percent)) / 100) * 0x10000 +
        Math.floor((G * (100 - percent)) / 100) * 0x100 +
        Math.floor((B * (100 - percent)) / 100)
      )
        .toString(16)
        ?.slice(1)
    );
  }

  useEffect(() => {
    // Set true if either the pathname is '/register-as-professional' or the token is missing
    if (window.location.pathname === "/register-as-professional") {
      setIsRegisterAsProfessional(true);
    }
  }, []);

  const onHeaderSearchIcon = () => {
    // navigate(`/search/?query=${searchText}`);
    window.open(`/search/?query=${searchText}`);
    // window.open(`https://www.unitedwecare.com/search/?query=${searchText}`, '_blank').focus();
  };

  const handleEnterKeyDown = (event) => {
    //debugger;
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      onHeaderSearchIcon(); // Trigger the search function
    }
  };

  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    logout();
    // setHasRedirected(false);
    Cookies.remove("authUserRole", {
      domain: ".unitedwecare.com",
      path: "/",
      HttpsOnly: true,
    });
    Cookies.remove("authToken", {
      domain: ".unitedwecare.com",
      path: "/",
      HttpsOnly: true,
    });
    Cookies.remove("authDisplayName", {
      domain: ".unitedwecare.com",
      path: "/",
      HttpsOnly: true,
    });
    Cookies.remove("authDataJwt", {
      domain: ".unitedwecare.com",
      path: "/",
      HttpsOnly: true,
    });
    // Cookies.remove("authUserRole");
    // Cookies.remove("authToken");
    // Cookies.remove("authDisplayName");
    // Cookies.remove("authDataJwt");
    setTimeout(() => console.log("logging out"), 5000);
    window.location.href = "https://www.unitedwecare.com/";
    // navigate("/");
  };
  const search = useLocation().search;

  const token_url = new URLSearchParams(search).get("token");

  const validateTokenFunction = () => {
    if (cookie_token && !currentUser) {
      validate_token(cookie_token)
        .then((resp) => {
          if (resp?.ResponseCode === 200) {
            login(resp?.data);
            setCurrentUser(resp?.data?.userdetails);

            let now = new Date();
            let oneYearFromNow = new Date(
              now.setFullYear(now.getFullYear() + 1)
            );
            let firstName = resp?.data?.userdetails?.Name.split(" ")
              .slice(0, 1)
              .join("");
            document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
            document.cookie = "userType=expert/user;";
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error in validate_token with token_url:", error);
        });
    }
  };

  useEffect(() => {
    if (cookie_token && !currentUser) {
      validateTokenFunction();
    }
  }, []);

  // useEffect(() => {
  //   if (!currentUser) {
  //     // setShowLloginPopup(true);
  //     const currentUrl = encodeURIComponent(window.location.href);
  //     window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  //   }
  // }, [currentUser]);

  const navigateToDashboard = () => {
    if (authUserType?.includes("expert")) {
      navigate("/expert/dashboard");
    } else {
      navigate("/user-dashboard");
    }
  };

  return (
    <div className={``}>
      <nav
        className={`navbar navbar-expand-lg userdashboard--Navbar ${isRegisterAsProfessional ? "d-none" : ""
          }`}
      >
        <div className="container-fluid mx-5">
          <div className="d-flex align-items-center ">
            <a
              className="navbar-brand"
              href="https://www.unitedwecare.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={
                  window.location.host.includes("ask-sam.ai")
                    ? "https://images.unitedwecare.com/odoo/open/logo/AskSamLogo.webp"
                    : KariefyLogo
                }
                // src={KariefyLogo}
                width={150}
                className="d-block d-sm-none img-fluid"
                style={{ objectFit: "contain" }}
              />
              <img
                alt=""
                src={
                  window.location.host.includes("ask-sam.ai")
                    ? "https://images.unitedwecare.com/odoo/open/logo/AskSamLogo.webp"
                    : KariefyLogo
                }
                // src={KariefyLogo}
                width="326"
                height="50"
                fill="currentColor"
                className="bi bi-search me-3 d-none d-md-block"
                viewBox="0 0 16 16"
                style={{ objectFit: "contain" }}
              />
            </a>

            {currentUser?.header_image && (
              <img
                src={currentUser?.header_image}
                className="img-fluid"
                style={{
                  width: "110px",
                  height: "65px",
                  objectFit: "contain",
                }}
                alt="logo"
              // onClick={() => navigate("/user-dashboard")}
              />
            )}
          </div>
          <div className="d-flex align-items-center border mx-5 userdashboard--Search w-100">
            <IconButton onClick={onHeaderSearchIcon}>
              <SearchIcon />
            </IconButton>
            <input
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={handleEnterKeyDown}
              className="me-2 w-100 search_bar"
              type="search"
              placeholder="Search Experts, Programs, Worksheet etc..."
              aria-label="Search"
              style={{ border: "none", backgroundColor: "transparent" }}
            />
          </div>

          {/* <button
            className="navbar-toggler d-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
            style={{ boxShadow: "none", border: "none" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav m-auto  align-items-center flex-grow-1 pe-3">
                <li className="nav-item w-100 d-none d-md-block">
                  <div className="d-flex align-items-center border-bottom mx-5">
                    <IconButton onClick={onHeaderSearchIcon}>
                      <SearchIcon />
                    </IconButton>
                    <input
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      onKeyDown={handleEnterKeyDown}
                      className="me-2 w-100 search_bar"
                      type="search"
                      placeholder="Search Experts, Programs, Worksheet etc..."
                      aria-label="Search"
                      style={{ border: "none", backgroundColor: "transparent" }}
                    />
                  </div>
                </li>

                
              </ul>
            </div>
          </div> */}
          <div className="userdashboard--Navbar__user d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center mr-4 p-1"
              style={{
                //border: "1px solid lightblue",
                borderRadius: "30px",
                minWidth: "max-content",
              }}
            >
              <img
                src={currentUser?.ProfileImage}
                onError={(e) => {
                  const newLink = handleBrokenImage(currentUser?.ProfileImage);
                  e.target.src = newLink;
                }}
                width={42}
                height={42}
                alt=""
                style={{
                  borderRadius: "50%",
                  cursor: "pointer",
                  outline: "2px solid var(--primary-color) !important",
                  outlineOffset: "2px",
                }}
                onClick={navigateToDashboard}
              />
              <span
                style={{
                  fontWeight: "500",
                  marginLeft: "10px",
                  fontFamily: 'Roboto", sans-serif',
                }}
              >
                Hi, {currentUser?.Name.split(" ")[0]}
              </span>
              <IconButton onClick={handleMenuClick}>
                <div className="d-flex align-items-center"></div>
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}

                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </div>
            <a
              onClick={() => navigate(`/search/?query=${searchText}`)}
              // target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton className="d-none userdashboard--Search__BTN">
                <SearchIcon />
              </IconButton>
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
