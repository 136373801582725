export const handleBrokenImage = (imgLink) => {

    const imgLinkSplit = imgLink.split("https://");
    if (imgLinkSplit.length > 1) {
        const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
        return updatedLink;
    } else {
        return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp"
    }
}


export const handleImage = (profileImage) => {
    // Logic to correct the broken image URL if needed
    const handleBroken = (imgLink) => {
        const imgLinkSplit = imgLink.split("https://");

        if (imgLinkSplit.length > 1) {
            const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
            return updatedLink;
        } else {
            return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp";
        }
    };

    // First, fix the image URL using your link splitting logic
    const fixedImageLink = handleBroken(profileImage);

    // Return a promise that resolves to the final image URL
    return new Promise((resolve) => {
        const img = new Image();
        img.src = fixedImageLink;

        // If the image loads successfully, resolve to the fixed (and valid) image URL
        img.onload = () => resolve(fixedImageLink);

        // If the image fails, resolve to the fallback image URL (same as default)
        img.onerror = () => resolve("https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp");
    });
};