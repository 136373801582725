import { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";
import DashboardHeader from "./component/DashboardHeader";

//Icons
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import LockIcon from "@mui/icons-material/Lock";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import { Helmet } from "react-helmet";
import ThemeSwitcher from "../../ThemeSwitcher";
import { AutoAwesome } from "@mui/icons-material";



// Mapping menu names to their icon components
const iconMap = {
  Dashboard: DashboardOutlinedIcon,
  Appointments: EventAvailableOutlinedIcon,
  "Session Management": EventAvailableIcon,
  Clients: GroupOutlinedIcon,
  Chat: ForumOutlinedIcon,
  "My Schedule": CalendarMonthOutlinedIcon,
  Worksheets: EventAvailableOutlinedIcon, // Assuming a generic icon for demonstration
  Articles: EventAvailableOutlinedIcon, // Assuming a generic icon for demonstration
  Posts: SettingsOutlinedIcon,
  Notifications: NotificationsOutlinedIcon,
  Communities: HeadsetMicOutlinedIcon,
  "Help Center": HeadsetMicOutlinedIcon,
  Payouts: PaymentOutlinedIcon,
  Settings: ManageAccountsOutlinedIcon,
  Copilot: AutoAwesome
};

const menus = [
  { name: "Dashboard", imageLink: "/assets/img/", link: "dashboard" },
  { name: "Appointments", imageLink: "/assets/img/", link: "appointments" },
  { name: "Copilot", imageLink: "/assets/img/", link: "copilot" },
  {
    name: "Session Management",
    imageLink: "/assets/img/",
    link: "sessionmanagement",
  },
  { name: "Clients", imageLink: "/assets/img/", link: "clients" },
  { name: "Chat", imageLink: "/assets/img/", link: "chat" },
  { name: "My Schedule", imageLink: "/assets/img/", link: "my-schedule" },
  { name: "Worksheets", imageLink: "/assets/img/", link: "worksheets" },
  // { name: "Articles", imageLink: "/assets/img/", link: "articles" },
  { name: "Notifications", imageLink: "/assets/img/", link: "notifications" },
  // { name: "Communities", imageLink: "/assets/img/", link: "communities" },
  { name: "Help Center", imageLink: "/assets/img/", link: "help-center" },
  { name: "Payouts", imageLink: "/assets/img/", link: "payouts" },
  { name: "Settings", imageLink: "/assets/img/", link: "settings" },

];

const Dashboard = () => {
  const [isActive, setIsActive] = useState("");
  const location = useLocation();
  const currentUrl = location.pathname;
  let currentPathName =
    currentUrl?.split("/")[currentUrl.split("/").length - 1];

  useEffect(() => {
    //console.log("currentUrl", currentPathName)

    let filteredData = menus.filter((item) => item.link == currentPathName);
    setIsActive(filteredData[0]?.name);
  }, [currentUrl]);
  const onClickMenu = (name) => {
    if (name === "Copilot") {
      window.open(`https://copilot${process.env.REACT_APP_CLIENT_DOMAIN}/auth`, "_blank");
      return
    } else {
      setIsActive(name);
    }

  };

  return (
    <>
      <Helmet>
        <title>Expert Dashboard</title>

      </Helmet>
      <ThemeSwitcher />
      <DashboardHeader />
      <Container
        fluid
        style={{ background: "" }}
        className="ExpertDashboard"
      >
        <Row style={{}} className="px-2">
          <Col style={{ background: "var(--primary-accent-color)", height: "100vh" }} md={2} className="px-0   border-end  ExpertDashboard--Col">
            <Nav className="flex-column px-3 gap-1 my-3 NavigationRail">
              {menus?.filter((item) => {
                const currentHost = window.location.host;
                // Australia specific logic domain needs to be updated
                if (currentHost.includes(".ask-sam.ai")) {
                  return item;
                }
                return item.name !== "Payouts";
              }).map((menu) => {
                const IconComponent = iconMap[menu.name]; // Dynamically get the icon component
                return (
                  <Nav.Link
                    key={menu.name}
                    as={Link}
                    to={menu.name !== "Copilot" && menu.link}
                    onClick={() => onClickMenu(menu.name)}
                    className="d-flex rounded-3 py-3 align-items-center gap-6"
                    style={{
                      background:
                        isActive === menu.name ? "var(--primary-accent-color)" : "",

                      fontSize: "14px",
                      color: isActive === menu.name ? "var(--primary-base-color)" : "black",
                    }}
                  >
                    <IconComponent
                      sx={{ color: isActive === menu.name ? "var(--primary-base-color)" : "#000" }}
                    />
                    <p className="font-inter fw-semibold">{menu.name}</p>

                  </Nav.Link>
                );
              })}
            </Nav>
          </Col>

          {/* Content */}
          <Col style={{ background: "#ffffff" }} md={10} className="pt-3 ExpertDashboard--Col">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
