import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getExpertbyId_WebService,
  AddAppointmentUserService,
  UserDetailsService,
  // updatePhoneNumber,
  SubscriptionDiscount,
  AddUserPackageService,
  AddTransactionService,
  UpdateTransactionService,
  UseCouponCodeService,
  Get_Appointment_Detailsbyid,
  IndianpackagewithpriceService,
  getInstituteDiscountOODOService,
  isFirstTimeAppointment,
} from "./core/_request";
import { useAuth } from "../auth";
import { getCountryCode, getCurrencyCode } from "./core/commonFun";
import { AppContext } from "../context";
import StripeCheckout from "react-stripe-checkout";
import { useSnackbar } from "notistack";
import AlertPopup from "./AlertPopup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { RescheduledService } from "./user/core/_request";
import { Skeleton, Tooltip } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { handleBrokenImage } from "../lib/utils";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import axios from "axios";

const modal_data = [
  {
    title: "Please update your Mobile Number!",
    desc: "For a streamlined booking experience, please input your Mobile Number. We may contact you if necessary.",
  },
];

const loadRazorpayScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const ExpertBookingPreview = () => {
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];
  const [loading, setLoading] = useState(false);
  const [couponloading, setCouponloading] = useState(false);
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [couponCode, setCouponCode] = useState();
  const [number, setNumber] = useState();
  const [couponCodeMessage, setCouponCodeMessage] = useState();
  const [readMoreExperties, setReadMoreExperties] = useState();
  const [expertDetailsData, setExpertDetailsData] = useState();
  const [isValid, setValid] = useState();
  const [data, setData] = useState();
  const [discountedPercentage, setDiscountedPercentage] = useState();
  const [showScreenerModal, setShowScreenerModal] = useState(false);

  const { id } = useParams();
  const search = useLocation().search;
  const navigate = useNavigate();
  const countryCode = getCountryCode();
  const CurrencyCode = getCurrencyCode();
  const { currentUser, updateMobileNo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const appid = new URLSearchParams(search).get("appid");
  const [showModal, setShowModal] = useState(false);
  const [mainAmountAfterCoupon, setMainAmountAfterCoupon] = useState();
  const mobileNumberRegex = /^(?:\+?1[-. ]?)?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
  const finalAmount = expertDetailsData?.USD_Amount - (expertDetailsData?.USD_Amount * discountedPercentage / 100);

  useEffect(() => {
    if (currentUser?.MobileNo) {
      const savedPhoneNumber = (currentUser?.MobileNo);
      setValid(mobileNumberRegex.test(savedPhoneNumber));
    }
    console.log("setValid", isValid)
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getExpertbyId_WebService(id);
      const data1 = resp.data;
      setExpertDetailsData(data1);

      let tdata = undefined;
      if (appid) {
        tdata = await getAppDataByAppId();
      } else {
        tdata = JSON.parse(localStorage.getItem("bookAnAppointmentData"));
      }
      const mainAmount = tdata.callTime === 30 ? data1.countryAmount / 2 : data1.countryAmount;
      tdata["mainAmount"] = mainAmount;
      setData(tdata);
    };

    fetchData();
  }, [id, appid]);

  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    const user_email = JSON.parse(localStorage.getItem("profile"))?.Emailid;
    const body = { params: { userId: user_id, email: user_email, expert_id: id } };

    SubscriptionDiscount(body).then((resp) => {
      if (resp?.discountPercentage >= 0) {
        setDiscountedPercentage(resp?.discountPercentage);
      } else {
        enqueueSnackbar(resp?.discountPercentage, { variant: "error" });
      }
    });
  }, [id, enqueueSnackbar]);

  const applyCoupon = () => {
    if (!couponCode) {
      setCouponCodeMessage("Please fill coupon code.");
      return;
    }

    setCouponloading(true);
    const body = { CouponCode: couponCode, Type: expertDetailsData.RegisterYourselfAs, Amount: data.mainAmount };

    UseCouponCodeService(body).then((resp) => {
      if (resp.data !== "") {
        setMainAmountAfterCoupon(resp.data);
      }
      setCouponCodeMessage(resp.ResponseMessage);
      setCouponloading(false);
    });
  };

  const handleMobileNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/[^0-9]/g, "");
    setNumber(inputNumber);
    setValid(/^[1-9][0-9]{9}$/.test(inputNumber));
  };

  const handlePayment = async (token) => {
    setLoading(true);
    const mobileNumber = number || currentUser?.MobileNo.replace(/^\+00\+1/, "");

    if (!isValid || !mobileNumber) {
      setShowModal(true);
      setLoading(false);
      return;
    }

    if (isValid && number) {
      await updatePhoneNumber();
      updateMobileNo(number);
    }

    const AppointmentId = new URLSearchParams(search).get("AppointmentId");
    const appointmentBody = {
      ExpertId: id,
      AppointmentDate: moment(data?.selectedDate)?.format("YYYY-MM-DD"),
      App_StartTime: data.selectSlot.Time_Duration,
      CallMode: "Video_Call",
      CallTime: data.callTime,
    };

    if (AppointmentId) {
      appointmentBody.AppointmentId = AppointmentId;
      RescheduledService(appointmentBody).then((resp) => {
        enqueueSnackbar("Appointment Successfully Rescheduled!", { variant: "success" });
        // navigate("/user-dashboard");
        navigate(`/appointment-confirmed/${id}`);
      });
    } else {
      await processAppointment(appointmentBody, token);
    }
  };

  const updatePhoneNumber = async () => {
    const apiUrl = `https://integrations.uwc.world/users/${currentUser?.UserId}/update`;
    const apiKey = process.env.REACT_APP_STELLAAPIKEY;

    const requestData = {
      country: currentUser?.Country,
      name: currentUser?.Name,
      time_zone: "Asia/Kolkata",
      phone: number
    };

    try {
      const res = await axios({
        method: 'post',
        url: apiUrl,
        headers: {
          'X-API-Key': apiKey,
          'Content-Type': 'application/json',
        },
        data: requestData,
      });
    } catch (err) {
      // Set error if something goes wrong
      enqueueSnackbar(err.response ? err.response.data : err.message, { variant: "warning" });
      // setError(err.response ? err.response.data : err.message);
    }
  };

  const processAppointment = async (appointmentBody, token) => {
    const finalAmount = expertDetailsData?.USD_Amount - (expertDetailsData?.USD_Amount * discountedPercentage / 100);
    appointmentBody.stripeToken = token.id;
    appointmentBody.amount = finalAmount;
    appointmentBody.currency = "USD";

    AddAppointmentUserService(appointmentBody).then(async (resp) => {
      if (resp.ResponseCode === 200) {
        const userDetails = await UserDetailsService().then((resp) => resp.data);
        const packageBody = {
          ExpertId: data?.selectSlot?.Expert,
          PackageName: "Pay As You go - USA",
          Country: currentUser.Country,
          Type: expertDetailsData?.RegisterYourselfAs,
          HSTAmount: 0,
          AppointmentCount: 0,
          DurationInMonths: 0,
          Amount: finalAmount,
        };

        AddUserPackageService(packageBody).then((pkgResp) => {
          addTransaction(resp.data, finalAmount, userDetails, pkgResp.data, token);
        });
      } else {
        enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
        setLoading(false);
      }
    });
  };

  const addTransaction = (appointmentData, amount, userDetails, packageData, token) => {
    const transactionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "_web";
    const transactionBody = {
      ExpertId: id,
      PackageId: packageData.PackageId,
      TransactionId: transactionId,
      Amount: amount,
      isMarkAutoRenew: false,
      TransactionType: "Credit Card",
      Billing_Address_Master: {
        TransactionId: transactionId,
        Name: userDetails.Name,
        Email: userDetails.Emailid,
        Mobile: userDetails.MobileNo,
      },
      PaymentGateway_OrderId: token?.razorpay_payment_id,
    };

    AddTransactionService(transactionBody).then(() => {
      UpdateTransactionService({ TransactionId: transactionId, Status: "Completed" }).then(() => {
        isFirstTimeAppointment().then((resp) => {
          if (resp?.data?.Appointment_count === 1) {
            // window.open(`https://stella.unitedwecare.com/?token=${authToken}&message=backendstart_Screener_Intro&appURL=${encodeURI("https://us.unitedwecare.com")}`);
            enqueueSnackbar("Appointment Successfully Booked!", { variant: "success" });
            navigate(`/appointment-confirmed/${id}`);
            // setShowScreenerModal(true);
          } else {
            enqueueSnackbar("Appointment Successfully Booked!", { variant: "success" });
            // navigate("/user-dashboard");
            navigate(`/appointment-confirmed/${id}`);
          }
        });
      });
    });
  };

  const getAppDataByAppId = async () => {
    if (!currentUser) {
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
      return;
    }

    const outData = {};
    const appData = await Get_Appointment_Detailsbyid(appid);
    outData.selectedDate = appData?.data?.AppointmentDate;
    outData.selectSlot = { Time_Duration: appData.data.App_StartTime };
    outData.callTime = appData.data.Minutes;
    return outData;
  };

  const onClickNavigate = () => {
    navigate(`/book-an-appointment/${id}`);
  };

  const handleCloseScreenerConfirmation = () => {
    navigate("/user-dashboard");
    setShowScreenerModal(false);
  };

  return (
    <>
      {
        !expertDetailsData ?
          <div
            className="d-flex align-items-center justify-content-center mx-auto px-2 px-md-5"
            style={{
              height: "100%",
              width: "100vw",
              background: "#FFF",
              paddingBottom: "100px "
            }}
          >
            <div className='w-100'>
              <Skeleton variant="rounded-1" width="30%" height={40} className="mx-auto rounded-3 my-4" />
              <div className="col-md-7  mx-auto OrderPreview--Skeleton">
                <div
                  className='d-flex sm:flex-col gap-3 '
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "rgba(0, 0, 0, 0.09)",
                    borderRadius: "20px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className='OrderPreview--Skeleton__Left'
                    style={{
                      padding: "45px 20px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <Skeleton
                      variant="rounded-1"
                      width={130}
                      height={130}
                    />
                    <div className='flex-1'>
                      {Array.from(new Array(4)).map((_, index) => (
                        <Skeleton
                          variant="text"
                          width={`${100 - (index * 10)}%`}
                          height={30}
                        />
                      ))}
                    </div>
                  </div>

                  <div className='OrderPreview--Skeleton__Right' style={{ padding: "45px 20px", }} >
                    <Skeleton
                      variant="rounded"
                      width={250}
                      height={130}
                    />
                    <div className='mt-3'>
                      {Array.from(new Array(4)).map((_, index) => (
                        <Skeleton
                          variant="text"
                          width={`${100 - (index * 10)}%`}
                          height={30}
                        />
                      ))}
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          : (
            <div className='d-block pb-2' style={{ background: "#F7F2FF", minHeight: "100vh" }}>
              <div className="col-md-7 mx-auto px-2 px-md-5 rounded-3">
                <h5 className="font-w600 text-center fs-2 py-3">Review Session Details</h5>
                <div className="bg-white rounded-2">
                  <div className="row ">
                    <div className="col-md-6 py-3 py-md-5" style={{ border: "1px solid #E2E2E1", backgroundColor: "#fff" }}>
                      <div className="row align-items-center">
                        <img
                          src={expertDetailsData?.ProfileImage}
                          onError={(e) => {
                            const newLink = handleBrokenImage(expertDetailsData?.ProfileImage);
                            e.target.src = newLink
                          }}
                          className="col-5 col-md-6 rounded-5 p-4"
                          alt=""
                        />
                        <div className="col-6">
                          <p className="font-w600 fs-6 font-inter lh-base text-black">{expertDetailsData?.Name}</p>
                          <p className="font-inter fs-6 lh-base">{expertDetailsData?.RegisterYourselfAs}</p>
                          <p className="font-inter fs-6 lh-base">Years of Experience: {expertDetailsData?.Experience}</p>
                          <p className="font-inter fs-6 lh-base"><img src="/assets/img/usa-20.png" alt="" /> United States</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 py-3 py-md-5" style={{ border: "1px solid #E2E2E1", backgroundColor: "#fff" }}>
                      <div className="rounded-2 m-3 p-3 d-flex justify-content-between align-items-center" style={{ background: "rgb(204, 182, 236)", border: "1px solid #F7F2FF" }}>
                        <div>
                          <p className="font-inter text-black font-w600 fs-5">Video Session</p>
                          <p className="font-inter text-black font-w400 fs-6">{moment(data?.selectedDate)?.format("DD MMMM")}</p>
                          <p className="font-inter text-black font-w400 fs-6">{data.selectSlot?.Time_Duration} ({data?.selectSlot?.TimeZone})</p>
                          <p className="font-inter text-black font-w400 fs-6">{data.callTime} Min Individual Session</p>
                        </div>
                        <div>
                          <button onClick={onClickNavigate} className="border-0 bg-transparent"><img src="/assets/img/Mask group(7).png" alt="" /></button>
                        </div>
                      </div>
                      <div className="mx-2 mx-md-4 mt-4">
                        <h5 className="font-inter d-flex justify-content-between" style={{ justifyContent: "center" }}>Bill To
                          <span style={{ height: 1, width: "75%", backgroundColor: "#E2E2E1", alignSelf: "center" }} />
                        </h5>
                        <p className="font-inter font-w400 fs-6 d-flex mt-1 justify-content-between">Name: <span className="font-inter font-w500 fs-6 text-black">{currentUser?.Name}</span></p>
                        <p className="font-inter font-w400 fs-6 d-flex mt-1 justify-content-between">Email: <span className="font-inter font-w500 fs-6 text-black" style={{ overflow: "hidden" }}>{currentUser?.Emailid}</span></p>
                      </div>
                      <div className='m-4'>
                        <p className='font-inter font-w400 fs-6 d-flex justify-content-between'>Final Amount : <span className='font-inter font-w500 fs-6 text-black'>$ {finalAmount}</span></p>
                      </div>
                      <div className="d-flex justify-content-between justify-content-md-between m-2 mt-2 mx-md-4 rounded-2" style={{ border: "1px solid #D9D9D9" }}>
                        <input
                          className="rounded-2 border-0"
                          type="tel"
                          maxLength={10}
                          placeholder={currentUser?.MobileNo === "+00 0000000000" ? "Mobile Number(10 digits) *" : currentUser?.MobileNo.replace(/^\+00\+1/, "")}
                          onChange={handleMobileNumberChange}
                          value={number}
                          style={{ height: "45px", width: "80%", paddingLeft: 7 }}
                        />
                      </div>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "common.black",
                              "& .MuiTooltip-arrow": {
                                color: "common.black",
                              },
                              color: "common.white",
                              padding: "10px",
                              fontSize: '14px',
                            },
                          },
                        }}
                        title="For a streamlined booking experience, please input your Mobile Number. We may contact you if necessary.">
                        <ErrorOutlineIcon fontSize='small' className='ms-4' />
                      </Tooltip>
                      <span className="m-2 mt-2 mx-md-4 font-inter font-w400" style={{ fontSize: "0.90rem" }}>Mobile Number is Mandatory <span className="font-w600">*</span></span>
                    </div>
                  </div>
                </div>
                {discountedPercentage === 100 ? (
                  <Button
                    disabled={loading || !isValid}
                    id="professional_complete_payment"
                    className="col-11 col-md-4 px-3 border-0 font-w600 font-inter fs-6 lh-base d-block mx-auto my-5 text-white"
                    style={{ background: "var(--button-primary-color)", borderRadius: "10px", height: "48px" }}
                    onClick={handlePayment}
                  >
                    {loading ?
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <CircularProgress color="#fff" size={20} /> Loading
                      </div>
                      :
                      "Confirm Session"
                    }
                  </Button>
                ) : (
                  <Button
                    disabled={!isValid}
                    className="col-11 col-md-4 px-3 border-0 font-w600 font-inter fs-6 lh-base d-block mx-auto my-5 text-white"
                    style={{ background: "var(--button-primary-color)", borderRadius: "10px", height: "48px" }}
                  >
                    <StripeCheckout
                      token={handlePayment}
                      stripeKey={process.env.REACT_APP_STRIPE_KEY}
                      name={currentUser?.Name}
                      description={`Individual Session`}
                      image="https://app.uwc.world/Content/adminlogo.png"
                      ComponentClass="div"
                      panelLabel="Pay"
                      amount={Math.round(expertDetailsData?.USD_Amount - (expertDetailsData?.USD_Amount * discountedPercentage / 100)) * 100}
                      currency="USD"
                      email={currentUser?.Emailid}
                      locale="auto"
                      allowRememberMe
                    >
                      Confirm Session
                    </StripeCheckout>
                  </Button>
                )}
              </div>
              <AlertPopup showModal={showModal} setShowModal={setShowModal} data={modal_data[0]} />
              <Dialog className="Confirmation--Dialog" open={showScreenerModal} onClose={handleCloseScreenerConfirmation} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="justify-content-center d-flex">Did the Screener Open in a New Tab?</DialogTitle>
                <DialogContent style={{ maxWidth: "600px", width: "100%", minWidth: "100%" }}>
                  <DialogContentText id="alert-dialog-description" className="justify-content-center d-flex">
                    If it has not opened in new tab, click the 'Open Link' button below to access it <br /><br />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button onClick={() => window.open(`https://stella.unitedwecare.com/?token=${authToken}&message=backendstart_Screener_Intro&appURL=${encodeURI("https://us.unitedwecare.com")}`)} className="PrimaryCTA--Outlined flex-1">Open Link</button>
                  <button onClick={handleCloseScreenerConfirmation} className="PrimaryCTA flex-1">Confirm</button>
                </DialogActions>
              </Dialog>
            </div >
          )
      }
    </>
  )
}

export default ExpertBookingPreview;
