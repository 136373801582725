import React from "react";
import { TableRow, TableCell, Button, IconButton, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Visibility } from "@mui/icons-material";

const ScreenerTabItem = ({ type, id, title, submittedOn, onViewClick }) => {
  const handleViewClick = () => {
    onViewClick(title, id, type);
  };

  return (
    <TableRow hover className="">
      {/* Screener Title */}
      <TableCell>
        <Typography variant="body2">{title}</Typography>
      </TableCell>

      {/* Submitted On */}
      <TableCell>
        <Typography variant="body2">{submittedOn}</Typography>
      </TableCell>

      {/* Action Buttons (View & Print) */}
      <TableCell>
        <Button
          variant="text"
          sx={{
            borderRadius: '20px', // Rounded but not too much
            bgcolor: '#f5f5f5',
            color: '#414141',
            fontSize: '14px', // 14px as in the default theme
            textTransform: 'capitalize',
            '&:hover': {
              background: "#f5f5f5"
            }
          }} className="d-flex justify-content-center align-items-center gap-2 rounded-3 px-3 "
          onClick={handleViewClick}
        >
          <Visibility />
          View
        </Button>
        {/* Uncomment the print button if you want to add print functionality later */}
        {/* <IconButton>
          <LocalPrintshopOutlinedIcon />
        </IconButton> */}
      </TableCell>
    </TableRow>
  );
};

export default ScreenerTabItem;
