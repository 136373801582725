import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

const CourseTabItem = ({ img, title, status }) => {
  return (
    <TableRow className="Course--Item">
      {/* Course Image */}
      <TableCell>
        <Box
          component="img"
          src={img}
          width={122}
          height={69}
          alt={title}
          loading="lazy"
          className=" rounded-3"
          sx={{ backgroundColor: "rgb(0,0,0,0.07)" }}
        />
      </TableCell>

      {/* Course Title */}
      <TableCell>
        <Typography variant="body2">{title}</Typography>
      </TableCell>

      {/* Course Status */}
      <TableCell>
        <Typography
          variant="body2"
          className={status.toLowerCase()}
          sx={{
            color:
              status.toLowerCase() === "completed"
                ? "green"
                : status.toLowerCase() === "in progress"
                  ? "orange"
                  : "red",
          }}
        >
          {status}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default CourseTabItem;
