import { useState, forwardRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";

import Button from "@mui/material/Button";

//Icons
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";

import { useAuth } from "../../../../auth";
import { useDropzone } from "react-dropzone";
//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Loader spinner
import CircularProgress from '@mui/material/CircularProgress';

//Upload
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../firebaseConfig'
import moment from "moment";


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const NewWorksheet = () => {

  const { currentUser, userType, logout } = useAuth();

  const [worksheetSaveLoading, setWorksheetSaveLoading] = useState(null)
  const [thumbnailUploading, setThumbnailUploading] = useState(null)
  const [workSheetDetails, setWorkSheetDetails] = useState({
    ownerID: currentUser?.UserId,
    worksheetName: '',
    worksheetThumbnailImg: '',
    worksheetInstructions: "",
    worksheetDisclaimer: "",
    worksheetQuestions: []
  })

  const [newQuestion, setNewQuestion] = useState({
    question: '',
    questionType: '',
    answers: {
      option1: '',
      option2: '',
      option3: '',
      option4: ''
    },
    options: {
      activity: false,
      singleSelect: false,
      multiSelect: false,
      longText: false,
      shortText: false
    }

  })
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Firebase login
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        //console.log("SIGNED IN")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log("errorMessage",errorMessage)
        // ...
      });
  }, [])

  ///New Questions Region Start

  //Handle Question type selection
  const handleChange = (event) => {

    const { name, checked } = event.target;

    setNewQuestion((prevState) => ({
      ...prevState,
      options: {
        ...Object.fromEntries(
          Object.entries(prevState.options).map(([key, value]) => [key, key === name ? checked : false])
        ),
      },
    }));

  };

  const handleNewQuestionName = (e) => {
    setNewQuestion((prevState) => ({
      ...prevState,
      question: e.target.value
    }))
  }

  //console.log("newQuestion", newQuestion)


  ///New Questions Region End

  //Upload Thumbnail and get URL
  const handleFileUpload = async (acceptedFiles) => {
    try {
      let file = acceptedFiles[0];

      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setThumbnailUploading(true)
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //console.log(`Upload progress: ${percent}%`);
          },
          (error) => {
            console.error("Upload error:", error);
            // Handle upload error if needed
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              //console.log("File download URL:", url);
              setThumbnailUploading(false)
              setWorkSheetDetails((prevState) => ({
                ...prevState,
                worksheetThumbnailImg: url,
              }));
            } catch (error) {
              setThumbnailUploading(true)
              console.error("Error getting download URL:", error);
              // Handle error if needed
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      // Handle error if needed
    }
  };


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleFileUpload,
  });

  const handleWorksheetName = (e) => {
    //console.log()
    setWorkSheetDetails((prevState) => ({
      ...prevState,
      worksheetName: e.target.value
    }))
  }

  const handleOptions = (e) => {
    //console.log("EVENT", e)
    const { name, value } = e.target
    setNewQuestion((prevState) => ({
      ...prevState,
      answers: {
        ...prevState.answers,
        [name]: value
      }
    }))
  }

  const handleAddNewQuestion = () => {
    setWorkSheetDetails((prevState) => ({
      ...prevState,
      worksheetQuestions: [
        ...prevState.worksheetQuestions,
        newQuestion,
      ]
    }))
    setNewQuestion({
      question: '',
      questionType: '',
      answers: {
        option1: '',
        option2: '',
        option3: '',
        option4: ''
      },
      options: {
        //activity: false,
        singleSelect: false,
        multiSelect: false,
        longText: false
      }
    })


  }
  //console.log("WD", workSheetDetails)

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };


  const handleWorksheetInstructions = (e) => {
    setWorkSheetDetails((prevState) => ({
      ...prevState,
      worksheetInstructions: e.target.value
    }))
  }

  const handleWorksheetDisclaimer = (e) => {
    setWorkSheetDetails((prevState) => ({
      ...prevState,
      worksheetDisclaimer: e.target.value
    }))
  }

  const saveNewWorksheet = async () => {
    let questions = [];
    setWorksheetSaveLoading(true)

    workSheetDetails?.worksheetQuestions.map(item => {
      console.log("ITEM", item)
      questions.push({
        question_name: item.question,
        question_type: item.options.singleSelect ? 'selection' :
          item.options.longText ? 'longtext' :
            item.options.multiSelect ? 'multiselection' :
              item.options.shortText ? 'shorttext' :
                item.options.activity ? 'activity' : '',
        options: Object.values(item.answers).filter(option => option !== '').map(option => {
          return ({
            option_name: option
          })
        })
      })
    })

    const worksheetBody = {
      jsonrpc: "2.0",
      params: {
        name: workSheetDetails?.worksheetName,
        owner_id: workSheetDetails?.ownerID,
        instruction: workSheetDetails?.worksheetInstructions,
        disclaimer: workSheetDetails?.worksheetDisclaimer,
        questions,
        attachment: {
          file: "",
          url: workSheetDetails?.worksheetThumbnailImg,
          description: "Worksheet Thumbnail"
        }
      }
    }

    const headers = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
    };

    try {

      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_worksheet_template/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(worksheetBody)
        }
      );
      const postResponse = await response.json();

      //console.log("postResponse", jwtDecode(postResponse?.data))


      if (postResponse?.ResponseCode == 200 || postResponse?.ResponseCode == 201) {
        setSnackbarMessage(postResponse?.ResponseMessage);
        setOpenSnackbar(true);
        setWorkSheetDetails({
          ownerID: currentUser?.UserId,
          worksheetName: '',
          worksheetThumbnailImg: '',
          worksheetInstructions: "",
          worksheetDisclaimer: "",
          worksheetQuestions: []
        })
        setWorksheetSaveLoading(false)
      }
    } catch (error) {
      console.error("Error:", error);
    }


    //console.log("payload", payload)
  }


  return (
    <>
      {
        worksheetSaveLoading === true && (
          <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'fixed', top: '0', left: '0', zIndex: '1000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )
      }
      <Grid container spacing={2} className="NewWorksheet--Grid">
        <Grid item xs={8} className="NewWorksheet--Left">
          <div>
            <p>Worksheet Name</p>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={workSheetDetails.worksheetName}
              onChange={handleWorksheetName}
              fullWidth
              sx={{ background: "#FFF" }}
            />
          </div>

          {
            workSheetDetails.worksheetQuestions?.map(item => (
              <div className="mt-5 bg-white rounded-2 p-3 Left--Container">
                <span>{item.options.activity ? 'Activity' : 'Question'}</span>
                <h6>
                  {item.question}
                </h6>

                <div>
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormGroup>

                      {Object.entries(item.answers).map(([key, question], index) => {
                        if (question !== "") {
                          return (
                            <FormControlLabel
                              key={index}
                              control={<Checkbox name={key} />}
                              label={question}
                            />
                          )
                        }
                      }
                      )}


                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            ))
          }


          <div className="mt-5 bg-white rounded-2 p-3 NewSection Left--Container">
            <span>Add a Question</span>
            <div>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup
                  sx={{ display: "flex !important", flexDirection: "row" }}
                >
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={newQuestion.options.activity}
                        onChange={handleChange}
                        name="activity"
                      />
                    }
                    label="Activity"
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newQuestion.options.longText}
                        onChange={handleChange}
                        name="longText"
                      />
                    }
                    label="Long Text"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newQuestion.options.singleSelect}
                        onChange={handleChange}
                        name="singleSelect"
                      />
                    }
                    label="Single Select"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newQuestion.options.multiSelect}
                        onChange={handleChange}
                        name="multiSelect"
                      />
                    }
                    label="Multi-Select"
                  />
                </FormGroup>
              </FormControl>
            </div>

            {/* <div className="d-flex align-items-center gap-2">
            <Button
              sx={{
                borderRadius: "5px",
                background: "#F3F3F3",
                color: "#000",
                fontSize: "16px",
                width: "176px",
                textTransform: "capitalize",
                fontWeight: "400",
                padding: "8px 10px",
              }}
            >
              <OndemandVideoOutlinedIcon sx={{ marginRight: "5px" }} />
              Add Video
            </Button>
            <Button
              sx={{
                borderRadius: "5px",
                background: "#F3F3F3",
                color: "#000",
                fontSize: "16px",
                width: "176px",
                textTransform: "capitalize",
                fontWeight: "400",
                padding: "8px 10px",
              }}
            >
              <MicNoneOutlinedIcon sx={{ marginRight: "5px" }} />
              Add Audio
            </Button>
          </div> */}

            <div className="CustomQuestion">
              <TextField
                id="outlined-basic"
                label={newQuestion.options.activity ? 'Activity' : 'Question'}
                variant="outlined"
                className=""
                value={newQuestion.question}
                onChange={handleNewQuestionName}
                fullWidth
              />

              {
                (newQuestion.options.singleSelect || newQuestion.options.multiSelect) && (
                  <>
                    <hr />

                    <TextField
                      id="outlined-basic"
                      label="Option 1"
                      variant="outlined"
                      name="option1"
                      value={newQuestion.answers.option1}
                      onChange={handleOptions}
                      fullWidth
                    />

                    <TextField
                      id="outlined-basic"
                      label="Option 2"
                      variant="outlined"
                      name="option2"
                      value={newQuestion.answers.option2}
                      onChange={handleOptions}
                      fullWidth
                    />

                    <TextField
                      id="outlined-basic"
                      label="Option 3"
                      variant="outlined"
                      name="option3"
                      value={newQuestion.answers.option3}
                      onChange={handleOptions}
                      fullWidth
                    />

                    <TextField
                      id="outlined-basic"
                      label="Option 4"
                      variant="outlined"
                      name="option4"
                      value={newQuestion.answers.option4}
                      onChange={handleOptions}
                      fullWidth
                    />
                  </>
                )
              }


              <Button
                variant="contained"
                sx={{ width: "max-content" }}
                disableElevation
                disabled={newQuestion.question !== "" ? false : true}
                className="PrimaryCTAExp"
                onClick={handleAddNewQuestion}
              >
                Add Question
              </Button>
            </div>
          </div>

          <div className="mt-3 Left--Data">
            {/* <p>Instructions</p> */}
            <div className="mt-2 bg-white rounded-2 p-3">
              <TextField
                label="Instructions"
                id="outlined-basic"
                variant="outlined"
                className=""
                value={workSheetDetails?.worksheetInstructions}
                onChange={handleWorksheetInstructions}
                fullWidth
                multiline
              />
            </div>
          </div>

          <div className="mt-3 Left--Data">
            {/* <p>Disclaimer</p> */}
            <div className="mt-2 bg-white rounded-2 p-3">
              <TextField
                label="Disclaimer"
                id="outlined-basic"
                variant="outlined"
                className=""
                value={workSheetDetails?.worksheetDisclaimer}
                onChange={handleWorksheetDisclaimer}
                fullWidth
                multiline
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={4} className="NewWorksheet--Right">
          <div className="bg-white rounded-4 p-3">
            <span>Attention: Unsaved Changes</span>
            <p>Please save your progress to keep your changes.</p>
            <div className="d-flex align-items-center gap-2 mt-3 Actions--Container">
              {/* <Button>
              <PreviewOutlinedIcon />
              Preview
            </Button> */}

              <Button disabled={(workSheetDetails.worksheetQuestions.length > 0 && workSheetDetails.worksheetInstructions !== "" && workSheetDetails.worksheetDisclaimer !== "" && workSheetDetails.worksheetThumbnailImg !== "" && workSheetDetails.worksheetName !== "") ? false : true} className="SaveButton" onClick={saveNewWorksheet}>
                <SaveAltOutlinedIcon />
                Save
              </Button>
            </div>

            <p className="my-3">Thumbnail Image</p>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {
                thumbnailUploading == null &&
                (
                  <div>
                    <ImageOutlinedIcon />
                    <p>Upload Image</p>
                    <span>Suggested size 1270x600 </span>
                  </div>
                )

              }

              {
                thumbnailUploading ? (
                  <div className="mx-auto" style={{ border: 'none' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  workSheetDetails?.worksheetThumbnailImg !== "" ? (
                    <img src={workSheetDetails?.worksheetThumbnailImg} style={{ borderRadius: '10px' }} alt="Uploaded" />
                  ) : null
                )
              }

            </div>
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>
  );
};

export default NewWorksheet;
