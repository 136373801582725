import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import "bootstrap/dist/css/bootstrap.css";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import "./modals.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import axios from "axios";
import { Config } from "../../../core/_request";
import { data } from "jquery";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";

const SessionComplete = ({
  open,
  handleClose,
  appointment_id,
  status,
  setMessage,
  handleOpenSnackbar,
  setStatus,
  action
}) => {
  const emojisData = [
    { img: "🙁", status: false, answer: "bad" },
    { img: "😊", status: false, answer: "ok" },
    { img: "😍", status: false, answer: "very_good" },
  ];
  let feed_back_data = [
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
  ];
  const yesno = ["Yes", "No"];
  const [feedbackQuestion, setFeedbackQuestion] = useState([]);
  const [emojiStatus, setImojiStatus] = useState({ index: 0, status: false });
  const [emojiStatus2, setImojiStatus2] = useState({ index: 0, status: false });
  const [permission, setPermission] = useState("");
  const [emojis, setEmojis] = useState(emojisData);
  const [emojis2, setEmojis2] = useState(emojisData);
  const [qaList, setQaList] = useState({});
  let [feedbackAnsers, setFeedbackAnsers] = useState(feed_back_data);

  useEffect(() => {
    setPermission(status);
    if (
      status === "read" &&
      appointment_id !== 0 &&
      appointment_id &&
      appointment_id !== ""
    ) {
      sessionFeedbacksByUserId();
    }
    setEmojis(emojisData);
    setEmojis2(emojisData);
    setFeedbackAnsers(feed_back_data);
  }, [status, appointment_id]);

  useEffect(() => {
    let isEmojiSelected = [...emojis];
    let emojistatusdata = isEmojiSelected.map((data, index) => {
      if (index === emojiStatus?.index) {
        return { ...data, status: emojiStatus?.status };
      }
      return { ...data, status: false };
    });
    setEmojis(emojistatusdata);
  }, [emojiStatus]);

  useEffect(() => {
    let isEmojiSelected = [...emojis2];
    let emojistatusdata = isEmojiSelected.map((data, index) => {
      if (index === emojiStatus2?.index) {
        return { ...data, status: emojiStatus2?.status };
      }
      return { ...data, status: false };
    });
    setEmojis2(emojistatusdata);
  }, [emojiStatus2]);

  useEffect(() => {
    sessionFeedbacksQuestion();
  }, []);

  const sessionFeedbacksByUserId = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_id: userId,
      appointment_id: appointment_id,
      user_type: "expert",
    };
    if (!userId) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/expert-feedback-details`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          const selectedEmoji = emojis?.map((data) => {
            if (data.answer === resp?.data[0]?.qa_list[0]?.answer) {
              return { ...data, status: true };
            }
            return { ...data, status: false };
          });
          setEmojis(selectedEmoji);
          const selectedEmoji1 = emojis2?.map((data) => {
            if (data.answer === resp?.data[0]?.qa_list[1]?.answer) {
              return { ...data, status: true };
            }
            return { ...data, status: false };
          });
          setEmojis2(selectedEmoji1);
          setFeedbackAnsers(resp?.data[0]?.qa_list);
        }
      });
  };

  const sessionFeedbacksQuestion = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_type: "expert",
      user_id: userId,
      session_status: "completed",
    };
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-form`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setFeedbackQuestion(resp?.data?.template_data);
        }
      });
  };

  const setAnswer = (que, answer, index) => {
    setQaList({ ...qaList, [index]: { question_id: que, answer: answer } });
    if (feedbackAnsers && feedbackAnsers.length > 0) {
      feedbackAnsers[index].answer = answer;
    }
  };

  useEffect(() => {
    console.log(qaList);
  }, [qaList]);

  const submit = () => {


    if (Object.keys(qaList).length < 7) {
      setMessage("All fields are required !");
      setStatus("error");
      handleOpenSnackbar();
      return;
    }
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_type: "expert",
      user_id: userId,
      appointment_id: appointment_id,
      session_status: "completed",
      qa_list: Object.values(qaList),
    };
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/feedback-form-data`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        setMessage(resp.data.message);
        setStatus("success");
        handleOpenSnackbar();
      });
    setQaList({})
    setImojiStatus({ index: 0, status: false });
    setImojiStatus2({ index: 0, status: false });
    handleClose();
    action()
  };

  const checkValidation = () => { };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setQaList({})
        setFeedbackAnsers(feed_back_data)
        setImojiStatus({ index: 0, status: false });
        setImojiStatus2({ index: 0, status: false });
        handleClose()
      }}
      // BackdropProps={{ style: { backgroundColor: "transparent" } }}
      PaperProps={{
        style: { width: "788px", maxWidth: "1000px", height: "903px" },
      }}
      style={{ width: "100%", height: "720px" }}
    >
      <DialogTitle
        className="text-white "
        style={{
          backgroundColor: "var(--primary-base-color)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >

        Session Completed
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setQaList({})
            setFeedbackAnsers(feed_back_data)
            setImojiStatus({ index: 0, status: false });
            setImojiStatus2({ index: 0, status: false });
            handleClose()
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="d-flex text-black" style={{ overflowX: "hidden", }}>
        <Grid container spacing={3} className="my-4">
          {/* Left Column */}
          <Grid item xs={12} md={5} className="mx-3">
            {/* First Feedback Question */}
            <FormControl component="fieldset" className="mb-4">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[0]?.question}
              </FormLabel>
              <Grid container spacing={1}>
                {emojis.map((emoji, index) => (
                  <Grid item key={index}>
                    <IconButton
                      disabled={status === "read"}
                      onClick={() => {
                        setAnswer(feedbackQuestion[0]?.question_id, emoji.answer, 0);
                        setImojiStatus({ index: index, status: true });
                      }}
                      style={{
                        border: emoji?.status
                          ? "2px solid var(--primary-base-color)" : "2px solid var(--primary-base-opacity30)",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    >
                      <span style={{ fontSize: "27px", color: "black" }}>{emoji?.img}</span>
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </FormControl>

            {/* Second Feedback Question */}
            <FormControl component="fieldset" className="mb-4">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[2]?.question}
              </FormLabel>
              <RadioGroup

                value={
                  feedbackAnsers?.length > 0 ? feedbackAnsers[2]?.answer : ""
                }
                onChange={(e) => setAnswer(feedbackQuestion[2]?.question_id, e.target.value, 2)}
              >
                {feedbackQuestion[2]?.options?.map((option, index) => (
                  <FormControlLabel
                    disabled={status === "read"}
                    key={index}
                    value={option?.name}
                    control={<Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--primary-base-color)", // Custom color for checked state
                        },
                      }}
                    />}
                    label={option?.name}
                    className="custom-font1"
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {/* Third Feedback Question */}
            <FormControl component="fieldset" className="mb-4">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[5]?.question}
              </FormLabel>
              <RadioGroup
                value={
                  feedbackAnsers?.length > 0 ? feedbackAnsers[5]?.answer : ""
                }
                onChange={(e) => setAnswer(feedbackQuestion[5]?.question_id, e.target.value, 5)}
              >
                {feedbackQuestion[5]?.options?.map((option, index) => (
                  <FormControlLabel
                    disabled={status === "read"}
                    key={index}
                    value={option?.name}
                    control={<Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--primary-base-color)", // Custom color for checked state
                        },
                      }}
                    />}
                    label={option?.name}
                    className="custom-font1"
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={5} className="mx-3">
            {/* Fourth Feedback Question */}
            <FormControl component="fieldset" className="mb-4">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[1]?.question}
              </FormLabel>
              <Grid container spacing={1}>
                {emojis2.map((emoji, index) => (
                  <Grid item key={index}>
                    <IconButton
                      disabled={status === "read"}
                      onClick={() => {
                        setAnswer(feedbackQuestion[1]?.question_id, emoji.answer, 1);
                        setImojiStatus2({ index: index, status: true });
                      }}
                      style={{
                        border: emoji?.status
                          ? "2px solid var(--primary-base-color)" : "2px solid var(--primary-base-opacity30)",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    >
                      <span style={{ fontSize: "27px", color: "black" }}>{emoji?.img}</span>
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </FormControl>

            {/* Yes/No Question */}
            <FormControl component="fieldset" className="mb-4">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[3]?.question}
              </FormLabel>
              <RadioGroup
                value={feedbackAnsers?.length > 0 ? feedbackAnsers[3]?.answer : ""}
                onChange={(e) => setAnswer(feedbackQuestion[3]?.question_id, e.target.value, 3)}
              >
                {yesno.map((option, index) => (
                  <FormControlLabel
                    disabled={status === "read"}
                    key={index}
                    value={option}
                    control={<Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--primary-base-color)", // Custom color for checked state
                        },
                      }}
                    />}
                    label={option}
                    className="custom-font1"
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {/* Another Yes/No Question */}
            <FormControl component="fieldset" className="mb-4">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[4]?.question}
              </FormLabel>
              <RadioGroup
                value={feedbackAnsers?.length > 0 ? feedbackAnsers[4]?.answer : ""}
                onChange={(e) => setAnswer(feedbackQuestion[4]?.question_id, e.target.value, 4)}
              >
                {yesno.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    disabled={status === "read"}
                    control={<Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--primary-base-color)", // Custom color for checked state
                        },
                      }}
                    />}
                    label={option}
                    className="custom-font1"
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {/* Sixth Feedback Question */}
            <FormControl component="fieldset">
              <FormLabel className="labels customLabel">
                {feedbackQuestion[6]?.question}
              </FormLabel>
              <RadioGroup
                value={
                  feedbackAnsers?.length > 0 ? feedbackAnsers[6]?.answer : ""
                }
                onChange={(e) => setAnswer(feedbackQuestion[6]?.question_id, e.target.value, 6)}
              >
                {feedbackQuestion[6]?.options?.map((option, index) => (
                  <FormControlLabel
                    disabled={status === "read"}
                    key={index}
                    value={option?.name}
                    control={<Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--primary-base-color)", // Custom color for checked state
                        },
                      }}
                    />}
                    label={option?.name}
                    className="custom-font1"
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-start" }}>
        {permission === "read" ? (
          ""
        ) : (
          <Button
            onClick={submit}
            style={{
              backgroundColor: "var(--primary-base-color)",
              textTransform: "none",
              color: "white",
              padding: "14px 24px",
              borderRadius: "8px",
              marginLeft: "30px",
              position: "absolute",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontsize: "18px",
              lineHeight: "130%",
              marginBottom: "60px",
              width: "99.53px",
              height: "40.89px",
            }}
            disabled={checkValidation()}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SessionComplete;
