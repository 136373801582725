import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./RegisterAsProfessional.css";
import RegisterAsProfessionalProfileDetailTab from "./RegisterAsProfessionalProfileDetailTab";
import RegisterAsProfessionalProfessionalDetailTab from "./RegisterAsProfessionalProfessionalDetailTab";
import RegisterAsProfessionalSchedulingTab from "./RegisterAsProfessionalSchedulingTab";
import RegisterAsProfessionalPaymentTab from "./RegisterAsProfessionalPaymentTab";
import RegisterAsProfessionalSummaryTab from "./RegisterAsProfessionalSummaryTab";
import ThankYouTab from "./ThankYouTab";
import {
  usOnBoarding_registrationStatus,
  usOnBoarding_updateProfilePicture,
} from "../core/_request";
import { toast } from "react-toastify";
import { getAuth, signInAnonymously } from "firebase/auth";
import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebaseConfig";
import { Helmet } from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";

const RegisterAsProfessional = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState("profile");
  const [profileImage, setProfileImage] = useState();
  const [data, setData] = useState({});
  const [registeredData, setRegisteredData] = useState({});
  const [loading, setLoading] = useState();
  const [tabStatus, setTabStatus] = useState({
    profile: false,
    professional: false,
    scheduling: false,
    payment: false,
    summary: false,
  });

  var user_id =
    JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
    JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;

    useEffect(() => {
      const auth = getAuth();
      signInAnonymously(auth)
        .then(() => {
          // Signed in..
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // Handle errors
        });
    
      var body = { user_id: user_id };
      usOnBoarding_registrationStatus(body).then((resp) => {
        setRegisteredData(resp.data);
        
        // Set tab status based on response
        setTabStatus({
          profile: resp.data.profile === true,
          professional: resp.data.professional === true,
          scheduling: resp.data.scheduling === true,
          payment: resp.data.payment === true,
          summary: resp.data.summary === true, // If there's a summary status
        });
      });
    }, [user_id, currentTab]);
    

  useEffect(() => {
    sessionStorage.removeItem("Email_Verified_id");
    sessionStorage.removeItem("UsOnBoarding");
    sessionStorage.removeItem("User_Registered_Info");
  }, []);

  const handleSelect = (eventKey) => {
    setCurrentTab(eventKey);
  };

  const Save_Profile = (url) => {
    var user_id =
      JSON.parse(sessionStorage.getItem("Email_Verified_id")) ??
      JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
      JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
    if (user_id === undefined || user_id === null || user_id === "") {
      enqueueSnackbar("Please verify your email id first.", { variant: "warning" });
      return false;
    }
    var body = {
      user_id: user_id,
      image_link: url,
    };
    usOnBoarding_updateProfilePicture(body).then((resp) => {
      if (resp?.code === 200) {
        enqueueSnackbar("Profile Picture Updated Successfully", {
          variant: "success",
        });
        setData({ ...data, profile_image: url });
        sessionStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
        setProfileImage(true);
      } else {
        enqueueSnackbar(resp?.data?.error ?? resp?.message, {
          variant: "error",
        });
      }
    });
  };


  const handleImageUpload = async (event) => {
    setLoading(true);

    var file = event.target.files[0];
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg"
    ) {
      var dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => enqueueSnackbar(err, { variant: "error" }),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setLoading(false);
            Save_Profile(url);
          });
        }
      );
    } else {
      toast("Incorrect  file format.");
      enqueueSnackbar("Incorrect  file format.", { variant: "warning" });
    }
  };

  const handleTabSuccess = (tabName) => {
    setTabStatus((prevStatus) => ({
      ...prevStatus,
      [tabName]: true,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Register as Professional</title>
      </Helmet>

      <Container>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="profile"
          activeKey={currentTab}
          onSelect={(key) => setCurrentTab(key)}
        >
          <div className="d-block d-lg-none">
            <p className="font-inter text-center fs-6 font-w500 text-black-50 my-5">
              We apologize for the inconvenience. The Expert onboarding feature
              is currently only available on desktop devices. For the best
              experience, we kindly request that you access the onboarding
              process from a desktop computer or laptop. Thank you for your
              understanding. Should you have any questions or need further
              assistance, please feel free to reach out to our support team at
              professionalsus@unitedwecare.com.
            </p>
          </div>
          <div className="d-none d-lg-block">
            <Row className="">
              <Col
                sm={3}
                className="my-3 px-3 h-100 rounded-3"
                style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }}
              >
                <div className="py-3">
                  

                  <Nav className="flex-column my-4">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="profile"
                        className="text-black fs-6 font-inter d-flex align-items-center gap-3"
                        disabled={currentTab === "thankYou"}
                      >
                        <img src="/assets/img/Vector(21).png" alt="" />
                        Profile Details
                        {(tabStatus?.profile && (
                          <img src="/assets/img/done_black_24dp 1.png" alt="" />
                        )) || <img src="/assets/img/Vector (6).png" alt="" />}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="professionalDetail"
                        className="text-black fs-6 font-inter d-flex align-items-center gap-3"
                        disabled={currentTab === "thankYou"}
                      >
                        <img src="/assets/img/Vector(22).png" alt="" />
                        Professional Details
                        {(tabStatus?.professional && (
                          <img src="/assets/img/done_black_24dp 1.png" alt="" />
                        )) || <img src="/assets/img/Vector (6).png" alt="" />}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="scheduling"
                        className="text-black fs-6 font-inter d-flex align-items-center gap-3"
                        disabled={currentTab === "thankYou"}
                      >
                        <img src="/assets/img/Vector(23).png" alt="" />
                        Scheduling
                        {(tabStatus?.scheduling && (
                          <img src="/assets/img/done_black_24dp 1.png" alt="" />
                        )) || <img src="/assets/img/Vector (6).png" alt="" />}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="payment"
                        className="text-black fs-6 font-inter d-flex align-items-center gap-3"
                        disabled={currentTab === "thankYou"}
                      >
                        <img src="/assets/img/Vector(24).png" alt="" />
                        Payment
                        {(tabStatus?.payment && (
                          <img src="/assets/img/done_black_24dp 1.png" alt="" />
                        )) || <img src="/assets/img/Vector (6).png" alt="" />}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="summary"
                        className="text-black fs-6 font-inter d-flex align-items-center gap-3"
                        disabled={currentTab === "thankYou"}
                      >
                        <img src="/assets/img/Vector(25).png" alt="" /> Summary
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="thankYou"
                        className="d-none"
                        disabled
                      ></Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={9} className="rounded-4 px-3 my-3">
                <div
                  className="rounded-3"
                  style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }}
                >
                  <Tab.Content>
                    <Tab.Pane eventKey="profile">
                      <RegisterAsProfessionalProfileDetailTab
                        onClick={(e) => handleSelect(e)}
                        onNext={() => handleSelect("professionalDetail")}
                        onSuccess={() => handleTabSuccess("profile")}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="professionalDetail">
                      <RegisterAsProfessionalProfessionalDetailTab
                        onNext={() => handleSelect("scheduling")}
                        onSuccess={() => handleTabSuccess("professional")}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="scheduling">
                      <RegisterAsProfessionalSchedulingTab
                        onNext={() => handleSelect("payment")}
                        onSuccess={() => handleTabSuccess("scheduling")}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="payment">
                      <RegisterAsProfessionalPaymentTab
                        onNext={() => handleSelect("summary")}
                        onSuccess={() => handleTabSuccess("payment")}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="summary">
                      <RegisterAsProfessionalSummaryTab
                        registeredData={registeredData}
                        onClick={(e) => handleSelect(e)}
                        onNext={() =>
                          handleSelect(
                            "thankYou",
                            "profile",
                            "professionalDetail"
                          )
                        }
                        onSuccess={() => handleTabSuccess("payment")}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="thankYou">
                      <ThankYouTab />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </div>
        </Tab.Container>
      </Container>
    </>
  );
};

export default RegisterAsProfessional;
