import { useState, useEffect, forwardRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";

import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Button from "@mui/material/Button";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditProfessionalProfile = ({
  data: {
    register_as_id = "",
    language_ids = [],
    experience = "",
    documents = [],
    area_of_interest_ids = [],
    about_experience = "",
    school = null,
    graduation_year = null,
    resume = null,
    board_certification = null,
    licensed_state_id = [],
    license_type_id = [],
    license_type = null,
    license_expiry_date = null,
    license = null,
    treatment_id = null,
    mental_disorders_ids = [],
    mental_disorder = null,
    additional_certificates = [],
    npi_number = null,
  } = {},
}) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedMentalDisorders, setSelectedMentalDisorders] = useState([]);
  //const [profession, setProfession] = useState("Therapist");
  const [aboutExperience, setAboutExperience] = useState("");
  const [registeredAsList, setRegisteredAsList] = useState([]);

  const [registeredAs, setRegisteredAs] = useState("");
  const [registeredAsFields, setRegisteredAsFields] = useState();
  const [languageList, setLanguageList] = useState([]);
  const [areasOfInterestList, setAreasOfInterestList] = useState([]);
  const [languageIDs, setLanguageIds] = useState([]);
  const [areaOfInterests, setAreaOfInterests] = useState([]);
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLanguageIds(language_ids);
    setAreaOfInterests(area_of_interest_ids);
    setSelectedTreatments(treatment_id);
    setSelectedMentalDisorders(mental_disorders_ids);
    setAboutExperience(about_experience);
  }, [language_ids]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  //Registered As List
  useEffect(() => {
    const fetchResteredAs = async () => {
      try {
        const response = await fetch(
          "https://us.uwc.world/new-uwc/registration/registeras",
          {
            method: "GET",
            headers,
          }
        );

        const postResponse = await response.json();
        const registeredAsData = await postResponse.data?.register_as;
        setRegisteredAsList(registeredAsData);
        setLoading(false);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchResteredAs();
  }, [register_as_id]);

  //Professional Registered As
  useEffect(() => {
    const professionItem = registeredAsList?.filter(
      (item) => item.id == register_as_id
    );

    setRegisteredAs(professionItem[0]?.name);
    //console.log("professionItem[0]?.fields", professionItem[0]?.fields)
    setRegisteredAsFields(professionItem[0]?.fields);
  }, [register_as_id, registeredAsList]);

  //Language List
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(`https://us.uwc.world/new-uwc/languages`, {
          method: "GET",
          headers,
        });

        const postResponse = await response.json();
        const languageArray = await postResponse.data?.languages;
        //console.log("LANG", languageArray);
        setLanguageList(languageArray);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchLanguages();
  }, []);

  //Area of Interest
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const payload = {
          register_as_id,
        };
        const response = await fetch(
          `https://us.uwc.world/new-uwc/area-interest`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        const postResponse = await response.json();
        const areaOfInterestArray = await postResponse.data.area_of_interests;

        setAreasOfInterestList(areaOfInterestArray);
        console.log("AOI", areaOfInterestArray);
        //setLanguageList(languageArray);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchLanguages();
  }, []);

  const handleSelectlanguages = (event) => {
    //console.log("EV");
    setLanguageIds(event.target.value);
  };

  const handleSelectAOI = (e) => {
    setAreaOfInterests(e.target.value);
  };

  //Upload
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  //Clean Document Names
  const getFileNames = (url) => {
    const fileNameWithPrefix = url.substring(
      url.lastIndexOf("/") + 1,
      url.indexOf("?")
    );
    const fileNameWithoutSpaces = decodeURIComponent(
      fileNameWithPrefix.replace(/%20/g, " ")
    );
    const fileNameWithoutPrefix = fileNameWithoutSpaces.replace(
      "June_2023/",
      ""
    );
    return fileNameWithoutPrefix;
  };

  const fileNames = documents.map(getFileNames);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleTreatmentIDs = (e) => {
    setSelectedTreatments(e.target.value);
  };

  const handleMentalDisorders = (e) => {
    setSelectedMentalDisorders(e.target.value);
  };

  const handleAboutExperience = (e) => {
    setAboutExperience(e.target.value);
  };

  const handleUpdateProfessionalProfile = async () => {
    const payload = {
      uwc_user_id: userID,
      about_experience: aboutExperience,
      area_of_interest_ids: areaOfInterests,
      treatment_id: selectedTreatments,
      language_ids: languageIDs,
      mental_disorders_ids: selectedMentalDisorders,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/update-professional-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse.code;

      if (postResponse.code == 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //console.log("areaOfInterests", areaOfInterests);
  //console.log("experience", experience);
  return (
    <>
      <div className="Content--Section">
        <h6>Professional Profile</h6>
        <span>
          You are registered as a <b>{registeredAs}</b>
        </span>

        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {/* Language */}
          {registeredAsFields?.language_ids ? (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel sx={{ background: "#fff" }}>Language</InputLabel>
                <Select
                  fullWidth
                  multiple
                  value={languageIDs}
                  label="Language"
                  onChange={handleSelectlanguages}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (id) =>
                          languageList.find((lang) => lang.id === id)?.name
                      )
                      .join(", ")
                  }
                >
                  {languageList.map((lang) => (
                    <MenuItem key={lang.id} value={lang.id}>
                      <Checkbox
                        checked={languageIDs.includes(lang.id)}
                        name={lang.name}
                      />
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Skeleton variant="rounded" width="100%" height="50px" />
              </FormControl>
            </Grid>
          )}

          {/* Experience */}
          {registeredAsFields?.experience ? (
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="experience-text"
                label="Experience"
                variant="outlined"
                value={`${experience ? experience : 0} years`}
                disabled
              />
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Grid>
          )}

          {/* School */}
          {registeredAsFields?.school ? (
            <Grid item xs={8}>
              <TextField
                id="school"
                label="School"
                value={"AIT School of Mental Sciences"}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
          ) : (
            <Grid item xs={8}>
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Grid>
          )}

          {/* Graduation/Doctorate Year */}
          {registeredAsFields?.graduation_year ? (
            <Grid item xs={4}>
              <TextField
                id="grad-year"
                label="Graduation/Doctorate Year"
                value={graduation_year || 4}
                defaultValue={""}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Grid>
          )}
        </Grid>
      </div>

      <hr />
      <div className="Content--Section">
        {/* Uploaded Files */}
        {registeredAsFields?.documents && documents.length > 0 ? (
          <>
            <h6>UploadedFiles</h6>
            <div className="UploadedFiles--Section">
              <TextField
                fullWidth
                id="uploaded-basic"
                label="Uploaded Files"
                variant="outlined"
                value={fileNames.join(", ")}
                className="mt-2"
              />
              {/* {documents.map((doc) => {
                <div className="UploadedItem">
                  <img src={doc} width={100} height={100} />
                  <p>CV.pdf</p>
                </div>;
              })} */}
            </div>
          </>
        ) : null}

        {/* Areas of interest */}
        {!loading && !registeredAsFields?.areas_of_interest ? (
          <FormControl fullWidth sx={{ marginTop: "15px" }}>
            <InputLabel sx={{ background: "#fff" }}>
              Areas of interest(multiple selection allowed)
            </InputLabel>
            <Select
              fullWidth
              multiple
              value={areaOfInterests}
              label="Areas of interest(multiple selection allowed)"
              onChange={handleSelectAOI}
              renderValue={(selected) => {
                const selectedInterests = selected.map(
                  (id) =>
                    areasOfInterestList.find((interest) => interest.id === id)
                      ?.name
                );
                return selectedInterests.join(", ");
              }}
            >
              {areasOfInterestList.map((interest) => (
                <MenuItem key={interest.id} value={interest.id}>
                  <Checkbox
                    checked={area_of_interest_ids.includes(interest.id)}
                    name={interest.name}
                  />
                  {interest.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Grid item xs={4}>
            <Skeleton variant="rounded" width="100%" height="50px" />
          </Grid>
        )}

        <div className="Content--Section mt-3 pt-3">
          <Grid container spacing={2}>
            {/* Licensed State */}
            {registeredAsFields?.licensed_state_id && (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel sx={{ background: "#fff" }}>
                    State where you are licensed to practice?
                  </InputLabel>
                  <Select
                    fullWidth
                    value={"Licensed State"}
                    label="State where you are licensed to practice?"
                  ></Select>
                </FormControl>
              </Grid>
            )}

            {/* License Type */}
            {registeredAsFields?.license_type_id ? (
              <>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ background: "#fff" }}>
                      Choose License Type
                    </InputLabel>
                    <Select
                      fullWidth
                      value={"Others"}
                      label="Choose License Type"
                    ></Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    id="license-type"
                    label="Specify License Type"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <Skeleton variant="rounded" width="100%" height="50px" />
                </Grid>

                <Grid item xs={4}>
                  <Skeleton variant="rounded" width="100%" height="50px" />
                </Grid>
              </>
            )}

            {/* License Expiry Date */}
            {registeredAsFields?.license_expiry_date ? (
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of expiration"
                    sx={{ width: "100%", paddingTop: "0" }}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              <Grid item xs={4}>
                <Skeleton variant="rounded" width="100%" height="50px" />
              </Grid>
            )}

            {/* License Upload */}
            {registeredAsFields?.license ? (
              <Grid item xs={8}>
                <div
                  className="upload-button"
                  style={{ marginTop: "0", height: "100%" }}
                >
                  <label
                    htmlFor="file-upload"
                    className="upload-button-label"
                    style={{ height: "100%", fontSize: "16px" }}
                  >
                    <FileUploadOutlinedIcon className="upload-button-icon" />
                    License
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="upload-button-input"
                  />
                  {selectedFiles.length > 0 && (
                    <div className="selected-files">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="file-name">
                          {file.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Grid>
            ) : (
              <Grid item xs={8}>
                <Skeleton variant="rounded" width="100%" height="50px" />
              </Grid>
            )}
          </Grid>
        </div>
      </div>

      <hr />

      <div className="Content--Section mt-3">
        <Grid container spacing={2}>
          {/* Treatment */}
          {!loading && !registeredAsFields?.treatment_id ? (
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ marginTop: "15px" }}>
                <InputLabel sx={{ background: "#fff" }}>
                  What do you treat?
                </InputLabel>
                <Select
                  fullWidth
                  multiple
                  value={selectedTreatments}
                  onChange={handleTreatmentIDs}
                  label="What do you treat?"
                ></Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Grid>
          )}

          {/* Mental Disorders */}
          {!loading && !registeredAsFields?.mental_disorders_id ? (
            <>
              <Grid item xs={4}>
                <FormControl fullWidth sx={{ marginTop: "15px" }}>
                  <InputLabel sx={{ background: "#fff" }}>
                    What mental disorders do you treat?
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    value={selectedMentalDisorders}
                    onChange={handleMentalDisorders}
                    label="What mental disorders do you treat?"
                  ></Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  sx={{
                    marginTop: "15px",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Specify Others"
                  value={"Specify Others"}
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Skeleton variant="rounded" width="100%" height="50px" />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <Skeleton variant="rounded" width="100%" height="50px" />
              </Grid>
            </>
          )}
        </Grid>

        {registeredAsFields?.additional_certificates ? (
          <h6 style={{ marginTop: "20px" }}>
            Upload any additional certifications (Optional)
          </h6>
        ) : (
          <h6 style={{ marginTop: "20px" }}>
            Upload any additional certifications (Optional)
          </h6>
        )}

        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "15px",
          }}
        >
          {/* Additional Certificate */}
          {registeredAsFields?.additional_certificates ? (
            <Grid item xs={4}>
              <div
                className="upload-button"
                style={{ marginTop: "0", height: "100%" }}
              >
                <label
                  htmlFor="file-upload"
                  className="upload-button-label"
                  style={{ height: "100%", fontSize: "16px" }}
                >
                  <FileUploadOutlinedIcon className="upload-button-icon" />
                  Upload
                </label>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  className="upload-button-input"
                />
                {selectedFiles.length > 0 && (
                  <div className="selected-files">
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="file-name">
                        {file.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <div
                className="upload-button"
                style={{ marginTop: "0", height: "100%" }}
              >
                <Skeleton variant="rounded" width="100%" height="50px" />
              </div>
            </Grid>
          )}

          {/* NPI Number */}
          {registeredAsFields?.npi_number ? (
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Provide your NPI number"
                variant="outlined"
              />
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Grid>
          )}
        </Grid>
      </div>

      {/* About Experience */}
      <div className="Content--Section mt-5">
        {registeredAsFields?.about_experience ? (
          <>
            <span>
              Write about your professional experience and specialization
            </span>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label=""
              multiline
              rows={4}
              value={aboutExperience}
              onChange={handleAboutExperience}
              defaultValue=""
              sx={{ marginTop: "15px" }}
            />

            <p
              style={{
                textAlign: "right",
                color: "#6A6C6A",
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              150 words left
            </p>
          </>
        ) : (
          <>
            <span>
              Write about your professional experience and specialization
            </span>
            <Skeleton variant="rounded" width="100%" height="150px" />
          </>
        )}
        <Button
          variant="contained"
          disableElevation
          className="PrimaryCTAExp"
          sx={{ marginLeft: "auto", display: "block", marginTop: "15px" }}
          onClick={handleUpdateProfessionalProfile}
        >
          Update Professional Details
        </Button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProfessionalProfile;
