import React, { useState, useEffect } from "react";
import "../../Styles/style.css"; // Import the custom CSS file
import cardimg2 from "../../../dashboard/images/Frame 1707481024.png";

import image1 from "../../images/message (1).png";
import image2 from "../../images/note.png";
import image3 from "../../images/npad.png";

import { getRandomExperts } from "../../_request";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../auth";
import { Skeleton } from "@mui/material";

function DashboardCards() {
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];

  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const moods = [
    {
      uri: "I feel happy",
      name: "Happy",
      emoji:
        "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fapp%20home%2Fv5%2Fhappy.png?alt=media",
    },
    {
      uri: "I feel surprised",
      name: "Surprised",
      emoji:
        "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fapp%20home%2Fv5%2Fsurprised.png?alt=media",
    },
    {
      uri: "I feel afraid",
      name: "Afraid",
      emoji:
        "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fapp%20home%2Fv5%2FAfraid.png?alt=media",
    },
    {
      uri: "I feel disgusted",
      name: "Disgusted",
      emoji:
        "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fapp%20home%2Fv5%2Fdisgusted.png?alt=media",
    },
    {
      uri: "I feel angry",
      name: "Angry",
      emoji:
        "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fapp%20home%2Fv5%2Fangry.png?alt=media",
    },

    {
      uri: "I feel sad",
      name: "Sad",
      emoji:
        "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fapp%20home%2Fv5%2Fsad.png?alt=media",
    },
  ];

  const [randomExpertsData, setRandomExpertsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRandomExperts = async () => {
      try {
        const { experts } = await getRandomExperts(
          currentUser?.UserId,
          "United States"
        );
        //console.log('randomExperts', randomExperts);
        setRandomExpertsData(experts);
      } catch (error) {
        console.error("Error fetching random experts:", error);
      }
    };

    fetchRandomExperts();
    setLoading(false);
  }, []);

  const onTalkToStella = () => {
    window.open(
      `https://stella.unitedwecare.com/?token=${authToken}&appURL=${encodeURI(
        `https://us.unitedwecare.com`
      )}`
    );
  };

  //console.log("randomExpertsData", randomExpertsData)
  return (
    <div className="userDashboard">
      <div className="userDashboard--cardsContainer mt-2 d-flex gap-1 align-items-center">
        {loading ? (
          <div
            className="userDashboard--moods w-100 rounded rounded-4"
            style={{
              backgroundColor: "rgb(0,0,0,0.07)",
            }}
          >
            <div className="card-content">
              <Skeleton
                variant="rounded"
                className="w-100 px-2 rounded rounded-3"
              />
              <div className="card-body d-flex gap-1 h-100 my-auto align-items-center justify-content-evenly">
                {Array.from(new Array(6)).map((_, index) => (
                  <>
                    <Skeleton
                      key={index}
                      variant="rounded"
                      sx={{
                        minWidth: "35px",
                        minHeight: "35px",
                        width: "35px",
                        height: "35px",
                      }}
                      className="rounded rounded-2"
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="userDashboard--moods"
            style={{
              // width: "400px",
              height:"170px",
              background: "var(--secondary-gradient)",
              padding: "20px",
              borderRadius: "15px",
              textAlign: "center",
              color: "white",
            }}
          >
            {/* <div className="">
            <img src={cardimg2} alt="" className="rounded" />
          </div> */}
            <div className="card-content">
              <div
                className="card-title text-light text-start"
                style={{ fontSize: "1.1rem" }}
              >
                How is your mood today?
              </div>

              <div className="card-body d-flex gap-2 justify-content-around">
                {moods.map((mood, i) => (
                  <div
                    key={i}
                    className="text-center my-auto"
                    onClick={() => {
                      window.open(
                        `https://stella.unitedwecare.com/?token=${authToken}&message=${mood.uri}&appURL=https://us.unitedwecare.com`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={mood.emoji}
                      alt={mood.name}
                      style={{
                        minWidth: 35,
                        minHeight: 35,
                        width: 35,
                        height: 35,
                      }}
                    />
                    <p
                      className="text-light mt-1"
                      style={{ fontWeight: "500", fontSize: "0.75rem" }}
                    >
                      {mood.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div
            className="userDashboard--appointments w-100 rounded rounded-4 d-flex flex-column justify-content-between"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.07)", // Adjusted the rgba syntax correctly
            }}
          >
            <div className="card-content">
              <Skeleton
                variant="rounded"
                className="w-100 px-2 rounded rounded-3"
              />
              <div className="card-body d-flex gap-1 align-items-center justify-content-evenly">
                {Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width="48px"
                    height="48px"
                    className="rounded rounded-circle"
                  />
                ))}
              </div>
              <Skeleton
                variant="rounded"
                width="120px"
                height="30px"
                className="mx-auto mb-2 rounded-3" // Added margin-bottom for spacing at the bottom
              />
            </div>
          </div>
        ) : (
          <div
            className="userDashboard--appointments"
            onClick={() => {
          //    navigate("/");
              window.location.href = window.location.origin
            }}
          >
            {/* <div className="rounded">
              <img alt="" src={cardimg2} className="rounded" />
            </div> */}
            <div
              className="card-content"
              style={{
                // width: "400px",
                height:"170px",
                background: "var(--secondary-gradient)",
                padding: "20px",
                borderRadius: "15px",
                textAlign: "center",
                color: "white",
              }}
            >
              <div
                className="d-flex justify-content-around align-items-center"
                style={{ cursor: "pointer" }}
                
              >
                <div
                  className="card-title text-light"
                  style={{ fontSize: "1.1rem" }}
                >
                  {" "}
                  Book an Appointment
                </div>
                <svg
                  className="w-6 h-6 text-light mb-1"
                  style={{ width: "10px", height: "13px" }}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 8 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                  />
                </svg>
              </div>

              <div className="card-body d-flex justify-content-evenly px-0">
                {randomExpertsData?.map((exp) => (
                  <img
                    key={exp.ID}
                    src={exp.ProfileImage}
                    className="rounded-circle img-fluid my-auto"
                    style={{
                      width: "48px",
                      height: "48px",
                      cursor: "pointer",
                      backgroundColor: "rgb(0,0,0,0.06)",
                    }}
                    alt=""
                    //onClick={() => clicked("clicked")}
                  />
                ))}
              </div>

              <div className="text-center " style={{ cursor: "pointer" }}>
                <div
                  className="badge rounded-pill px-5 py-2 m-0 p-0"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.1)",
                  }}
                >
                  Explore Experts
                </div>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div
            className="userDashboard--stella w-100 rounded rounded-4 d-flex flex-column justify-content-center"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.07)", // Corrected opacity
            }}
          >
            <div className="card-content">
              <Skeleton
                variant="rounded"
                className="w-100 px-2 rounded rounded-3 mt-0"
              />
              <div
                className="card-body d-flex w-100 flex-wrap flex-column align-items-center gap-2"
                style={{ width: "100%" }}
              >
                {Array.from(new Array(3)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width="30%"
                    height="30px"
                    className="rounded-pill my-1"
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="userDashboard--stella">
            <div
              className="card-content gap-2"
              style={{
                background: "var(--secondary-gradient)",
                padding: "20px",
                borderRadius: "15px",
                textAlign: "center",
                color: "white",
                height:"170px",
              }}
            >
              <div className="d-flex justify-content-between px-2 align-items-center">
                <div
                  className="card-title text-light"
                  style={{ fontSize: "1.1rem" }}
                >
                  Talk to Stella
                </div>
                <svg
                  className="w-6 h-6 text-light mb-1"
                  style={{ width: "10px", height: "13px", cursor: "pointer" }}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 8 14"
                  onClick={onTalkToStella}
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                  />
                </svg>
              </div>
              <div
                className="card-body   
 d-flex flex-wrap justify-content-center gap-1 userDashboard--stella__items pb-1"
              >
                <span
                  className="badge rounded-pill px-4 py-1 m-0 p-0 my-2 mt-0 d-flex align-items-center gap-1 justify-content-center"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.0)",
                    cursor: "pointer",
                  }}
                  onClick={onTalkToStella}
                >
                  <img alt="" src={image1} className="mx-1" /> What's on your
                  mind?
                </span>
                <span
                  className="badge rounded-pill px-4 py-1 m-0 p-0 my-2 mt-0 d-flex align-items-center gap-1 justify-content-center"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.0)",
                    cursor: "pointer",
                  }}
                  onClick={onTalkToStella}
                >
                  <img alt="" src={image2} className="mx-1" /> Share something
                </span>
                <span
                  className="badge rounded-pill px-4 py-1 m-0 p-0 my-2 mt-0 d-flex align-items-center gap-1 justify-content-center"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.0)",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      `https://stella.unitedwecare.com/?token=${authToken}&message=Take+a+test&appURL=https://us.unitedwecare.com`
                    )
                  }
                >
                  <img alt="" src={image3} className="mx-1" /> Take an
                  Assessment
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardCards;
