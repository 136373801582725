import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InboxRowCard from "../cards/InboxRowCard";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";

import isUserLoggedIn from "../helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../auth";
import jwtDecode from "jwt-decode";
import { getToken } from "../APIS/clientAPIS";

const Inbox = () => {
  const [inboxList, setInboxList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );
  const [isLoading, setIsLoading] = useState(true);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userType, logout } = useAuth();

  //Fetch Clients
  useEffect(() => {
    const chatClientsHeaders = {
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "ios",
      Lat: "1",
      Long: "1",
      Authorization: `Bearer ${bearerToken}`,
    };

    const postClientsData = async (retryCount = 0) => {
      const MAX_RETRY = 3; // Maximum number of retries
      try {
        const response = await fetch(
          `https://app.uwc.world/api/transaction/getMyAspirantsForExpert`,
          {
            method: "GET",
            headers: chatClientsHeaders,
          }
        );
        const postResponse = await response.json();
        if (postResponse?.ResponseCode == 200) {
          setClientList(jwtDecode(postResponse?.data));
        } else if (postResponse?.ResponseCode == 401 && retryCount < MAX_RETRY) {
          getToken(currentUser?.Emailid).then((res) => {
            setBearerToken(res);
            //console.log("postClientsData INBOX.JS")
            //setTimeout(() => postClientsData(retryCount + 1), 0);
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientsData().then(() => setIsLoading(false));
  }, [bearerToken]);

  return (
    <div
      className="InboxWidget border  Widget rounded-4"
      style={{ background: "#FFFFFF" }}
    >
      <div className="d-flex border-bottom justify-content-between p-3 align-items-center Widget--Header">
        <h6 className="font-inter fs-5">Inbox</h6>
        {clientList?.length > 0 && (
          <Link
            to="/expert/chat"
            className="text-decoration-none d-flex align-items-center gap-2"
          >
            <p className="font-inter" style={{ color: "var(--primary-base-color)" }}>
              View All{" "}
            </p>
            <ArrowRightAltIcon style={{ color: "var(--primary-base-color)" }} />
          </Link>
        )}
      </div>
      <div className="InboxWidget--Content">
        {
          isLoading ? (
          <div
          style={{
            display: "flex", flexDirection:"column", gap:"10px", margin: "5px 0px 5px 10px"
          }}
          >
          {Array.from(new Array(4)).map((_, index) => (
            <Box sx={{ pt: 0.5, display:"flex", gap:"10px" }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: "50%" }} />
            </Box>
          ))}
            
          </div>
          ) :(clientList?.length === 0 ? (
          <p style={{ margin: "0 auto 20px 0", textAlign: "center" }}>
            No Inbox Data
          </p>
        ) : (
          clientList
            ?.slice(0, 4)
            .map((item) => (
              <InboxRowCard
                key={item.Id}
                img={item.ProfileImage}
                name={item.Name}
                messageType={item.message_type}
                message={item.message}
                unreadMessages={item.count}
              />
            ))
        ))}
      </div>
    </div>
  );
};

export default Inbox;
