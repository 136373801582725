import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppointmentTimeService,
  OODOGetUserProfileV4Service,
} from "./core/_request";
import { useAuth } from "../auth";
import { getCurrencyCode } from "./core/commonFun";
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  ToggleButton,
} from "react-bootstrap";
import { AppContext } from "../context";
import { useContext } from "react";
import moment from "moment";
import LocationConfirmation from "./LocationConfirmation";
import "./ExpertFilterDrawer.css";
import "./DetailPageCard.css";
import { useSnackbar } from "notistack";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { handleBrokenImage } from "../lib/utils";

const DetailPageCard = (props) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { data } = props;
  const [sdata, setSdata] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const CurrencyCode = getCurrencyCode();
  const [show, setShow] = useState(false);
  const [calenderData, setCalenderData] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [slots, setSlots] = useState();
  const [locationConfirmation, setLocationConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    binddata();
  }, []);

  const binddata = () => {
    var dates = [];
    for (var i = 0; i <= 15; i++) {
      var date = moment(new Date()).add("d", i);
      dates.push({ date, avaliableSlots: "Loading", checked: false });
    }
    setCalenderData(dates);

    dates?.forEach((element, index) => {
      AppointmentTimeService(
        data.Id,
        moment(element.date).format("YYYY/MM/DD")
      ).then((resp) => {
        if (resp.ResponseCode === 200) {
          var length = resp?.data?.filter((o) => o.IsAvailable)?.length;
          // element.avaliableSlots = length == 0 ? "Not Avaliable" : length + " Available";
          // setCalenderData([...dates]);
          // if (index == 0) {
          //     setSlots(resp?.data?.filter(o => o.IsAvailable))
          // }
          if (length > 0) {
            element.avaliableSlots = length + " Available";
          } else {
            element.avaliableSlots = "Not Avaliable";
          }
          element.data = resp?.data?.filter((o) => o.IsAvailable);

          if (dates.filter((o) => o.avaliableSlots == "Loading").length == 0) {
            var ndata = dates
              .filter((o) => o.data.length > 0)
              .sort((o, p) => moment(o.data) < moment(p.data));
            if (ndata.length > 0) {
              var sdata = ndata[0];
              setSlots(sdata?.data?.filter((o) => o.IsAvailable));
              sdata.checked = true;
            }
          }
          setCalenderData([...dates]);
          setIsLoading(false);
        }
      });
    });
  };

  const getSlotByDate = (element) => {
    setCalenderData(
      calenderData?.map((o) => {
        if (o.date == element?.date) o.checked = true;
        else o.checked = false;
        return o;
      })
    );

    AppointmentTimeService(
      data?.Id,
      moment(element.date).format("YYYY/MM/DD")
    ).then((resp) => {
      if (resp?.ResponseCode == 200) {
        setSlots(resp?.data?.filter((o) => o.IsAvailable));
      }
    });
  };

  const onBookNow = (data) => {
    var selectSlot = slots?.filter((o) => o.checked);
    if (selectSlot?.length == 0) {
      enqueueSnackbar("Please select a time slot!", { variant: "warning" });
      return false;
    }
    setLoading(true);
    localStorage.setItem(
      "bookAnAppointmentData",
      JSON.stringify({
        selectedDate: calenderData?.find((o) => o.checked)?.date,
        selectSlot: slots?.find((o) => o.checked),
      })
    );
    if (currentUser) {
      if (data?.RegisterYourselfAs === "Therapist") {
        setLocationConfirmation(true);
        console.log("data", data);
      } else {
        navigate(`/book-an-appointment/${data?.Id}`);
      }
      // var body = { "user_id": data?.Id }
      // GetMySubscription(body).then(resp => {
      //     if (resp.ResponseCode === 200) {
      //         // if(resp.data){

      //         // }
      //     } else {
      //     }
      // })
    } else {
      // navigate(`/login?retrunurl=/book-an-appointment/${data?.Id}`)
      localStorage.setItem("retrunurl", `/book-an-appointment/${data?.Id}`);
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
    }
  };

  return (
    (data && (
      <div
        className="pg-height bg-white py-3 px-3 rounded-3 position-relative h-100"
        style={{ border: "1px solid #E2E2E1" }}
      >
        <div className="row align-items-center">
          {(isLoading && (
            <div className="d-flex flex-row gap-1">
              <div className="col-5">
                <Skeleton variant="rounded" width={145} height={145} />
              </div>
              <div className="col-7 mt-2">
                <p className="font-w600 fs-6 font-inter lh-base text-black">
                  <Skeleton variant="text" width="90%" />
                </p>
                <p className="font-inter fs-6 lh-base">
                  <Skeleton variant="text" width="90%" />
                </p>
                <p className="font-inter fs-6 lh-base">
                  <Skeleton variant="text" width="90%" />
                </p>
                {/* <p className="font-inter fs-6 lh-base">
                    <Skeleton variant="text" width="90%" />
                  </p> */}
                <p className="font-inter fs-6 lh-base">
                  <Skeleton variant="text" width="90%" />
                </p>
              </div>
            </div>
          )) || (
              <>
                <div className="col-5">
                  <img
                    src={data?.ProfileImage}
                    onError={(e) => {
                      const newLink = handleBrokenImage(data?.ProfileImage);
                      e.target.src = newLink
                    }}
                    className="col-12 rounded-3 p-1"
                    alt=""
                  />
                </div>
                <div className="col-7">
                  <p className="font-w600 fs-6 font-inter lh-base text-black">
                    {data?.Name}
                  </p>
                  <p className="font-inter fs-6 lh-base">
                    {data?.RegisterYourselfAs}
                  </p>
                  <p className="font-inter fs-6 lh-base">
                    Years of Experience : {data?.Experience} yrs
                  </p>
                  <p className="font-inter fs-6 lh-base mt-1">
                    <img src="/assets/img/usa-20.png" alt="" /> United States
                  </p>
                </div>
              </>
            )}
        </div>
        <hr />

        {/* Mobile */}
        <div>
          {isLoading ? (
            <>
              <Skeleton variant="rounded" width="50%" />
              <Grid container spacing={2}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "15px",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  {Array.from(new Array(3)).map((_, index) => (
                    <Grid item key={index} sx={{ minWidth: "100%", minHeight: "57px" }}>
                      <Skeleton variant="rounded" width={110} height={57} />
                    </Grid>
                  ))}
                </div>
              </Grid>

              <div className="my-3">
                <Skeleton variant="rounded" width="50%" />
                <Grid container spacing={2}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "15px",
                      width: "100%",
                      padding: "20px",
                    }}
                  >
                    {Array.from(new Array(3)).map((_, index) => (
                      <Grid item key={index} sx={{ minWidth: "100%", minHeight: "42px" }}>
                        <Skeleton variant="rounded" width={100} height={42} />
                      </Grid>
                    ))}
                  </div>
                </Grid>
              </div>
            </>
          ) : calenderData.length > 1 ? (
            <>
              <h5>Availability</h5>
              <ButtonGroup className="d-flex flex-row gap-3 col-12 my-2 overflow-auto time-availability-container pb-3">
                {calenderData
                  ?.filter((o) => o.data?.length > 0)
                  ?.map(
                    (item, index) =>
                      item.avaliableSlots !== "Loading" && (
                        <ToggleButton
                          key={index}
                          onClick={() => getSlotByDate(item)}
                          type="radio"
                          name="radio"
                          className="date-availability rounded-2 col-2 px-1 py-1 ms-1 h-auto"
                          style={{
                            background: item?.checked ? "var(--primary-accent-color)" : "#fff",
                            color: "#000000",
                            border: "1px solid var(--primary-accent-color)",
                            minWidth: "110px",
                            maxWidth: "150px",
                          }}
                        >
                          <p className="font-inter fs-6 text-center">
                            {moment(item.date).format("DD MMM") === moment().format("DD MMM")
                              ? "Today"
                              : moment(item.date).format("DD MMM")}
                          </p>
                          <p className="font-inter fs-6 text-center">
                            {item.avaliableSlots}
                          </p>
                        </ToggleButton>
                      )
                  )}
              </ButtonGroup>

              {slots && slots?.length === 0 ? (
                <p>No slots available</p>
              ) : (
                <div className="my-3">
                  <h5>Available Time Slots ({data?.TimeZone})</h5>
                  <div>
                    <ButtonGroup className="d-flex flex-row gap-3 col-12 my-2 overflow-auto ps-1 slot-availability-container pb-3">
                      {slots?.map((item, index) => (
                        <ToggleButton
                          onClick={() =>
                            setSlots(
                              slots?.map((o) => {
                                if (o.ID === item.ID) o.checked = true;
                                else o.checked = false;
                                return o;
                              })
                            )
                          }
                          type="radio"
                          name="radio"
                          className="time-availability rounded-2 py-2 px-0 col-3"
                          style={{
                            background: item?.checked ? "var(--primary-accent-color)" : "#ffffff",
                            color: "#000",
                            border: "1px solid var(--primary-accent-color)",
                            minWidth: "100px",
                            maxWidth: "150px",
                          }}
                        >
                          <p className="font-inter fs-6 text-center">
                            {item.Time_Duration}
                          </p>
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                </div>
              )}

              {/* Desktop */}
              <div className="d-flex justify-content-center ">
                <button
                  id="professional_detail_book_now"
                  onClick={() => onBookNow(data)}
                  className="book-now-a-professional px-3 px-md-3 border-0 font-w600 font-inter fs-6 lh-base text-white w-100"
                  style={{
                    borderRadius: "10px",
                    height: "48px",
                    background: "var(--primary-color)",
                  }}
                >
                  Book Now
                </button>
              </div>
            </>
          ) : (
            <div className="noSlot-container d-flex flex-column gap-3">
              <h6>Availability</h6>
              <div className="d-flex align-items-center gap-3 availabilityContainer">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.2661 7.77869C24.6807 7.77869 25.0161 8.1145 25.0161 8.52869V20.8685C25.0161 22.9479 23.325 24.639 21.2456 24.639H8.72998C8.31543 24.639 7.97998 24.3036 7.97998 23.889C7.97998 23.4745 8.31543 23.139 8.72998 23.139H21.2456C22.4973 23.139 23.5161 22.1202 23.5161 20.8685V8.52869C23.5161 8.1145 23.8516 7.77869 24.2661 7.77869ZM0.641113 20.8685V6.05566C0.641113 3.97632 2.33228 2.28479 4.41162 2.28479H6.07861V1.02136C6.07861 0.607178 6.41406 0.271362 6.82861 0.271362C7.24316 0.271362 7.57861 0.607178 7.57861 1.02136V2.28479H12.1064V0.955078C12.1064 0.540894 12.4419 0.205078 12.8564 0.205078C13.271 0.205078 13.6064 0.540894 13.6064 0.955078V2.28479H18.0786V1.02136C18.0786 0.607178 18.4141 0.271362 18.8286 0.271362C19.2432 0.271362 19.5786 0.607178 19.5786 1.02136V2.28479H21.2456C22.1652 2.28479 23.0451 2.62463 23.7259 3.21872L24.8337 2.11084C25.1267 1.81787 25.6013 1.81787 25.8943 2.11084C26.1873 2.40381 26.1873 2.87842 25.8943 3.17139L2.64429 26.4218C2.4978 26.5682 2.30591 26.6415 2.11401 26.6415C1.92212 26.6415 1.73022 26.5682 1.58374 26.4218C1.29077 26.1288 1.29077 25.6542 1.58374 25.3612L2.72028 24.2246C1.47612 23.5978 0.641113 22.3223 0.641113 20.8685ZM2.14111 20.8685C2.14111 21.9219 2.86575 22.8229 3.87709 23.0679L9.23814 17.7067H6.72168C6.30713 17.7067 5.97168 17.3712 5.97168 16.9567C5.97168 16.5421 6.30713 16.2067 6.72168 16.2067H10.7381L14.3292 12.6156H6.72168C6.30713 12.6156 5.97168 12.2798 5.97168 11.8656C5.97168 11.4514 6.30713 11.1156 6.72168 11.1156H15.8291L22.6563 4.28828C22.2581 3.9711 21.7642 3.78479 21.2456 3.78479H19.5786V5.04822C19.5786 5.4624 19.2432 5.79822 18.8286 5.79822C18.4141 5.79822 18.0786 5.4624 18.0786 5.04822V3.78479H12.9145C12.8943 3.78644 12.877 3.79651 12.8564 3.79651C12.8358 3.79651 12.8186 3.78644 12.7984 3.78479H7.57861V5.04822C7.57861 5.4624 7.24316 5.79822 6.82861 5.79822C6.41406 5.79822 6.07861 5.4624 6.07861 5.04822V3.78479H4.41162C3.15991 3.78479 2.14111 4.80359 2.14111 6.05566V20.8685Z"
                    fill="#F04438"
                  />
                </svg>
                <p>
                  Sorry! There are no available time slots for the expert at this moment.
                </p>
              </div>
              <button onClick={() => navigate("/")}>
                Select some other Expert
              </button>
            </div>
          )}
        </div>

        {/* Location confirmation modal */}
        <LocationConfirmation
          show={locationConfirmation}
          onHide={() => setLocationConfirmation(false)}
          data={data}
        />
      </div>
    )) || <div>Loading...</div>
  );
};

export default DetailPageCard;
