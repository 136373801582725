import React, { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  usOnBoarding_Disclaimer,
  usOnBoarding_registrationStatus,
} from "../core/_request";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const RegisterAsProfessionalSummaryTab = (props) => {
  // console.log("props", props);
  const [data, setData] = useState({});
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [loading, setLoading] = useState("unchecked");
  const [registeredData, setRegisteredData] = useState({});
  const handleClose = () => setDisclaimerModal(false);

  var user_id =
    JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
    JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
  useEffect(() => {
    var body = { user_id: user_id };
    usOnBoarding_Disclaimer(body).then((resp) => {
      if (resp.code === 200) {
        setData(resp.data);
      } else {
        enqueueSnackbar(resp?.data?.error ?? resp?.message, {
          variant: "error",
        });
      }
    });
  }, [user_id, registeredData]);

  const handleShow = () => {
    user_id =
      JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
      JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
    var body = { user_id: user_id };
    usOnBoarding_registrationStatus(body).then((resp) => {
      if (
        resp.code === 200 &&
        resp?.data?.profile === true &&
        resp?.data?.professional === true &&
        resp?.data?.scheduling === true &&
        resp?.data?.payment === true
      ) {
        setDisclaimerModal(true);
      } else {
        enqueueSnackbar("Please Complete Your Profile.", {
          variant: "warning",
        });
      }
    });
  };

  const disclaimerModalSubmit = () => {
    setDisclaimerModal(false);
    props.onNext();
    props.onSuccess();
  };

  const handleCheckBox = (event) => {
    if (event.target.checked) {
      setLoading("checked");
    } else {
      setLoading("unchecked");
    }
  };

  const profileTab = () => {
    props.onClick("profile");
  };

  const ProfessionalTab = () => {
    props.onClick("professionalDetail");
  };

  const SchedulingTab = () => {
    props.onClick("scheduling");
  };

  const PaymentTab = () => {
    props.onClick("payment");
  };

  return (
    <div>
      <div
        className="d-flex align-items-center gap-3 py-3 rounded-top px-3"
        style={{ background: "#DCD0EB" }}
      >
        <img src="/assets/img/Vector(28).png" alt="" />
        <p
          className="font-inter font-w500 text-black"
          style={{ color: "#21231E", fontSize: "24px" }}
        >
          Summary
        </p>
      </div>
      <div className="mx-3 my-2">
        <p
          className="font-inter font-w500 text-black"
          style={{ color: "#21231E", fontSize: "24px" }}
        >
          Information
        </p>
        <p
          className="font-inter"
          style={{ color: "#6A6C6A", fontSize: "16px" }}
        >
          Complete your information before we can proceed.
        </p>
      </div>
      <div className="mx-3 my-2">
        <div className="d-flex flex-column gap-3">
          {(props.registeredData?.profile && (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ background: "#73589B" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (1).png" alt="" />
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Profile Details
                </p>
              </div>
              <div>
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Completed
                </p>
              </div>
            </div>
          )) || (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ border: "1px solid #A8200D" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (9).png" alt="" />
                <p
                  className="font-inter"
                  style={{ fontSize: "16px", color: "#A8200D" }}
                >
                  Profile Details
                </p>
              </div>
              <div>
                <Button
                  className="rounded-3 border-0 text-white"
                  onClick={profileTab}
                  style={{
                    backgroundColor: "#73589B",
                    whiteSpace: "nowrap",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07);",
                  }}
                >
                  <p className="font-inter text-white">Complete Now</p>
                </Button>
              </div>
            </div>
          )}
          {(props.registeredData?.professional && (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ background: "#73589B" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (2).png" alt="" />
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Professional Details
                </p>
              </div>
              <div>
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Completed
                </p>
              </div>
            </div>
          )) || (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ border: "1px solid #A8200D" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (10).png" alt="" />
                <p
                  className="font-inter"
                  style={{ fontSize: "16px", color: "#A8200D" }}
                >
                  Professional Details
                </p>
              </div>
              <div>
                <Button
                  className="rounded-3 border-0 text-white"
                  onClick={ProfessionalTab}
                  style={{
                    backgroundColor: "#73589B",
                    whiteSpace: "nowrap",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07);",
                  }}
                >
                  <p className="font-inter text-white">Complete Now</p>
                </Button>
              </div>
            </div>
          )}
          {(props.registeredData?.scheduling && (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ background: "#73589B" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (3).png" alt="" />
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Scheduling
                </p>
              </div>
              <div>
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Completed
                </p>
              </div>
            </div>
          )) || (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ border: "1px solid #A8200D" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (11).png" alt="" />
                <p
                  className="font-inter"
                  style={{ fontSize: "16px", color: "#A8200D" }}
                >
                  Scheduling
                </p>
              </div>
              <div>
                <Button
                  className="rounded-3 border-0 text-white"
                  onClick={SchedulingTab}
                  style={{
                    backgroundColor: "#73589B",
                    whiteSpace: "nowrap",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07);",
                  }}
                >
                  <p className="font-inter text-white">Complete Now</p>
                </Button>
              </div>
            </div>
          )}
          {(props.registeredData?.payment && (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ background: "#73589B" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (4).png" alt="" />
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Payment
                </p>
              </div>
              <div>
                <p
                  className="font-inter text-white"
                  style={{ fontSize: "16px" }}
                >
                  Completed
                </p>
              </div>
            </div>
          )) || (
            <div
              className="col-12 rounded-3 d-flex justify-content-between align-items-center px-3"
              style={{ border: "1px solid #A8200D" }}
            >
              <div className="d-flex align-items-center gap-3 py-3">
                <img src="/assets/img/Vector (5).png" alt="" />
                <p
                  className="font-inter"
                  style={{ fontSize: "16px", color: "#A8200D" }}
                >
                  Payment
                </p>
              </div>
              <div>
                <Button
                  className="rounded-3 border-0 text-white"
                  onClick={PaymentTab}
                  style={{
                    backgroundColor: "#73589B",
                    whiteSpace: "nowrap",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.07);",
                  }}
                >
                  <p className="font-inter text-white">Complete Now</p>
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end my-5">
          <ButtonGroup className="d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3">
            <Button
              className="rounded-3 border-0 text-white lh-lg px-3"
              style={{ backgroundColor: "#73589B", whiteSpace: "nowrap" }}
              onClick={handleShow}
            >
              Next
            </Button>
          </ButtonGroup>
        </div>
        <Modal
          show={disclaimerModal}
          onHide={() => setDisclaimerModal(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="disclaimer-popUp"
        >
          <Modal.Body>
            <h5
              className="font-inter lh-lg text-center w-100"
              style={{ fontSize: 36 }}
            >
              Disclaimer
            </h5>
            <div className="px-5">
              <p
                dangerouslySetInnerHTML={{ __html: data?.disclaimer }}
                className="font-inter lh-lg"
                style={{ fontSize: "16px", color: "#21231E" }}
              ></p>
            </div>
            <hr />
            <div className="mx-2">
              <div className="d-flex gap-3 align-items-center">
                <Form.Check aria-label="T&C" onChange={handleCheckBox} />
                <label
                  className="font-inter font-w500"
                  style={{ fontSize: "16px", color: "#21231E" }}
                >
                  I understand and accept &nbsp;
                  <a
                    href="https://legal.unitedwecare.com/us/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0065FF" }}
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
              <p
                dangerouslySetInnerHTML={{ __html: data?.footer }}
                className="font-inter font-w500 ms-5 mt-2"
                style={{ fontSize: "12px", color: "#6A6C6A" }}
              ></p>
            </div>
            <div className="d-flex align-items-center justify-content-center my-3">
              <ButtonGroup className="d-flex flex-row align-items-center justify-content-center me-3">
                <Button
                  className="rounded-5 border-0 text-white py-2 px-4 font-inter lh-lg"
                  style={{ backgroundColor: "#73589B", whiteSpace: "nowrap" }}
                  onClick={disclaimerModalSubmit}
                  disabled={loading === "unchecked"}
                >
                  Register
                </Button>
              </ButtonGroup>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RegisterAsProfessionalSummaryTab;
