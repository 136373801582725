import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotCompleted from "../modals/NotCompleted";
import SessionComplete from "../modals/SessionCompleted";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context";
import { Avatar, Box, Button, Card, CardContent, Divider, Tooltip, Typography } from "@mui/material";
import { AccessTime, CallMissedOutgoing, CallMissedOutgoingRounded, Visibility } from "@mui/icons-material";
import { CalenderSvg } from "../helpers/Svg";
const MarkedSessions = ({
  session,
  status,
  markedSessions,
  openModal,
  handleOpenModal,
  handleCloseModal,
  openModal1,
  handleOpenModal1,
  handleCloseModal1,
  loading,
}) => {
  const [currPage, setcurrPage] = useState(1);
  const [appointmentId, setAppointmentId] = useState("");
  const cards = 5;
  const lastIndex = currPage * cards;
  const firstIndex = lastIndex - cards;
  const navigate = useNavigate();
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);
  const validateSession = (data) => {
    if (data?.session_status === "Not Completed") {
      handleOpenModal1();
    } else {
      handleOpenModal();
    }
    setAppointmentId(data?.appointment_id);
  };
  const searchBarParams = new URLSearchParams(window.location.search);
  const session_status = searchBarParams.get("completed_flag");
  //console.log("markedSessions", markedSessions);
  return (
    <div style={{ backgroundColor: "white" }}>

      <div

        className="row row-cols-1  row-cols-md-2 row-cols-xl-3 row-cols-xxl-4 ">
        {!loading && markedSessions &&
          markedSessions.length > 0 &&
          markedSessions?.map((data, index) => (
            <div className="p-2 h-100" key={index}>
              <Card
                className="rounded-3 h-100 position-relative "

                sx={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}

              >
                <div style={{

                  position: "absolute",
                  top: "24px",
                  width: "6px",
                  background: data.session_status === "Not Completed" ? "#F97066" : "#47CD89",
                  height: "40px",
                  borderRadius: "0px 10px 10px 0px"
                }}></div>
                <CardContent className="p-3" sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>

                  {/* Client Info Section */}
                  <div className="d-flex justify-content-center align-items-center  w-100  mb-3">
                    <Avatar
                      src={data.client_profile_picture}
                      alt="User"
                      sx={{ width: 40, height: 40, marginRight: '10px' }}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '12px', width: "100%" }}>
                      <Typography className="d-flex justify-content-between align-items-center" variant="subtitle1" fontWeight={700}>
                        {data.client_name} {data?.incompleted_flag && <Tooltip title="Missed meeting"><CallMissedOutgoingRounded sx={{ color: "#D92D20" }} /></Tooltip>}
                      </Typography>

                      <Box sx={{ flexGrow: 1 }}>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
                            Follow-up
                          </Typography>
                          <Typography variant="body2" component="span">
                            |
                          </Typography>
                          <Typography
                            className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
                            variant="body2" color="text.secondary"
                            sx={{
                              fontSize: '12px',
                              color: data.session_status === "Not Completed" ? "#D92D20" : "#079455",
                              bgcolor: data.session_status === "Not Completed" ? "#FEE4E2" : "#DCFAE6"
                            }}
                          >
                            {data.session_status === "Not Completed" ? "Not Completed" : "Completed"}
                          </Typography>
                        </Box>
                      </Box>
                    </div>

                  </div>

                  {/* Date and Time Section */}
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Typography variant="body2" sx={{ display: "flex", alignItems: "center", fontWeight: 600, fontSize: "14px" }}>
                      <CalendarMonthIcon sx={{ color: "var(--primary-base-color)", mr: 0.5 }} />
                      {data.start_date}
                    </Typography>
                    <Typography variant="body2" sx={{ display: "flex", alignItems: "center", fontWeight: 600, fontSize: "14px" }}>
                      <AccessTime sx={{ color: "var(--primary-base-color)", mr: 0.5 }} />
                      {data.start_time}
                    </Typography>
                  </div>
                  {/* {data?.incompleted_flag && (
                    <div className="d-flex align-items-center gap-1 mt-2">
                      <span
                        style={{
                          padding: "5px",
                          borderRadius: "50%",
                          background: "#ff000078",
                          border: "1px solid #ff00005c",
                        }}
                      ></span>
                      <Typography variant="body2" color="error">Missed meeting</Typography>
                    </div>
                  )} */}
                  {/* Status and Details Section */}
                  <div className="d-flex mt-2 justify-content-between align-items-center mb-2">



                    <Tooltip title="View Details">
                      <Button
                        fullWidth
                        variant="text"
                        sx={{
                          borderRadius: '20px', // Rounded but not too much
                          bgcolor: '#f5f5f5',
                          color: '#414141',
                          fontSize: '14px', // 14px as in the default theme
                          textTransform: 'capitalize',
                          '&:hover': {
                            background: "#f5f5f5"
                          }
                        }} className=" rounded-3" startIcon={<Visibility />} onClick={() => validateSession(data)}>

                        View Details
                      </Button>
                    </Tooltip>
                  </div>

                  {/* <Divider sx={{ my: 2 }} /> */}

                  {/* Missed Meeting Indicator */}


                  {/* Optional Session Dashboard Button */}
                  {/* {data.session_status !== "Not Completed" && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 2, borderRadius: "30px", height: "34px" }}
                      onClick={() => {
                        navigate(`/expert/sessionmanagement?view=sessionDashboard&clientID=${data.client_id}&appointmentID=${data.appointment_id}&completed_flag=${data.session_status === "Completed"}`);
                        setExpertDashboardData((prevData) => ({
                          ...prevData,
                          clientID: data.client_id,
                          clientName: data.client_name,
                          appointmentID: data.appointment_id,
                          appointmentDate: data.start_time_ts_ist,
                          bookingDate: data.booking_time_ts_ist,
                          consultationDate: data.start_date,
                        }));
                      }}
                    >
                      <SpaceDashboardOutlinedIcon sx={{ mr: 1 }} />
                      Session Dashboard
                    </Button>
                  )} */}
                </CardContent>
              </Card>
            </div>


          ))}
        <NotCompleted
          status={"read"}
          appointment_id={appointmentId}
          open={openModal1}
          handleClose={handleCloseModal1}
        />
        <SessionComplete
          status={"read"}
          appointment_id={appointmentId}
          open={openModal}
          handleClose={handleCloseModal}
        />
        {!loading && markedSessions && markedSessions.length === 0 && (
          <div className="NoDataAvailable">
            {CalenderSvg}
            <p>
              No Sessions Found
            </p>
          </div>
        )}
        {!loading && !markedSessions && (
          <div className="NoDataAvailable">
            {CalenderSvg}
            <p>
              No Sessions Found
            </p>
          </div>
        )}
      </div>
      <nav>
        <ul className="pagination">
          {/* <li className="page-item">
            <a href="#" className="page-link"
            onClick={prePage}>Prev</a>


          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default MarkedSessions;
