import React, { useContext, useEffect, useState } from "react";
import DashboardCards from "./DashboardCards";
import ExploreBlogs from "./ExploreBlogs";
import Appointments from "../Dashboard/Appointments";
import RecommendedPrograms from "./RecommendedPrograms";
import ProfessionalHelp from "./ProfessionalHelp";
import Sidebar from "../Dashboard/Sidebar";
import Sidebar2 from "./Sidebar2";

import Navbar from "./Navbar";
import { FeedbackFormCall, getDisclaimerUpdate } from "../../../core/_request";
import { AppContext } from "../../../../context";
import { useAuth } from "../../../../auth";
import DisclaimerModal from "../../../DisclaimerModal";

/*------------------------------------*/

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import OnFeedback from "../../../user/OnFeedback";
import { Helmet } from "react-helmet";
import Insurance from "./Insurance";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/*------------------------------------*/

function Main() {
  const [showSimpleModal, setShowSimpleModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const { currentUser } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [disclaimerData, setDisclaimerData] = useState();
  const [drawerOpenCSS, setDrawerOpenCss] = useState("userdashboardDrawerClosed")


  const handleSimpleModal = () => setShowSimpleModal(true);

  /*------------------------------------*/

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    setDrawerOpenCss("userdashboardDrawerOpen")
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDrawerOpenCss("userdashboardDrawerClosed")
  };

  /*------------------------------------*/

  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
  useEffect(() => {
    var body = {
      user_type: "user",
      user_id: user_id,
      current_time: getCurrentTime(),
    };

    FeedbackFormCall(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        //console.log("resp?.data?.show_popup", resp?.data?.show_popup);
        setAppointmentId(resp?.data?.appointment_id);
        if (resp?.data?.appointment_id && !resp?.data?.show_popup) {
          handleSimpleModal();
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   if (!currentUser) {
  //     // setShowLloginPopup(true);
  //     const currentUrl = encodeURIComponent(window.location.href);
  //     window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  //   }
  // }, []);

  useEffect(() => {
    let body = {
      user_id: user_id,
    };
    getDisclaimerUpdate(body).then((resp) => {
      // console.log(resp?.data);
      if (resp?.data?.updated == false) {
        //console.log("resp?.data?.show_popup", resp?.data?.show_popup);
        setDisclaimerData(resp?.data?.disclaimer);
        // console.log("resp?.dataresp?.dataresp?.data", resp?.data);
        setModalShow(true);
      }
    });
  }, []);

  const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <Helmet>
        <title>
          Your Personal Wellness Dashboard | United We Care
        </title>
      </Helmet>
      <Navbar />
      <OnFeedback
        handleClose={() => setShowSimpleModal(false)}
        show={showSimpleModal}
        appointmentId={appointmentId}
        feedBackStatusBool={true}
      />
      {/* <Box className={drawerOpenCSS} sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className="d-none d-md-block">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Mini variant drawer
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} className="d-none d-md-block">
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar2 open={open} />
          
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: "#F4F4F4" }}>
          <DrawerHeader />
          <DashboardCards />

          <ExploreBlogs />

          <Appointments />

          <RecommendedPrograms />

          <ProfessionalHelp />
        </Box>
      </Box> */}
      <main>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 d-none d-lg-inline">
                <Sidebar activeItem="Dashboard" />
              </div>

              <div
                className="col-lg-10 px-lg-5 py-md-3 ps-md-3 pt-md-3 pb-0 m-0"
                style={{
                  backgroundColor: "#F4F4F4",

                }}
              >
                <DashboardCards />

                {/* <ExploreBlogs /> */}

                <Appointments />

                <RecommendedPrograms />

                <ProfessionalHelp />
              </div>
            </div>
          </div>
          <DisclaimerModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            disclaimerData={disclaimerData}
          />
        </section>
      </main>
    </>
  );
}

export default Main;
