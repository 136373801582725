import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

//Icons
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

//Menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import { Link, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import { useAuth } from "../../../auth";
import { Avatar, Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

//Component Start
const AppointmentCard = ({
  appointmentDate,
  appointmentTime,
  appointmentID,
  name,
  img,
  clientType,
  joinURL,
  clientID,
  status,
  onReschedule,
  onAddNote,
  onPrescription,
  onCancel,
  selectedTab,
}) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isMatchingConditions, setIsMatchingConditions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showJoin, setShowJoin] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    const currentDate = dayjs();
    const appointmentDateTime = dayjs(
      `${appointmentDate} ${appointmentTime}`,
      "DD MMM YYYY hh:mm A"
    );

    //console.log("currentDate", currentDate.format("DD MMM YYYY hh:mm A"));
    const isToday = appointmentDateTime.isSame(currentDate, "day");

    const isWithinRange = currentDate.isBetween(
      appointmentDateTime.subtract(10, "minutes"),
      appointmentDateTime.add(20, "minutes"),
      null,
      "[)"
    );

    // const isWithinRange = appointmentDateTime.isBetween(
    //   currentDate.subtract(10, "minutes"),
    //   currentDate.add(20, "minutes"),
    //   null,
    //   "[)"
    // );

    // console.log(
    //   "10 mins before",
    //   appointmentDateTime.subtract(10, "minutes").format("DD MMM YYYY hh:mm A")
    // );
    // console.log(
    //   "30 mins after",
    //   appointmentDateTime.add(30, "minutes").format("DD MMM YYYY hh:mm A")
    // );
    // console.log("IsBetween", isWithinRange);

    const diffInHours = appointmentDateTime.diff(currentDate, "hours");
    const isWithin48Hours = diffInHours <= 48;

    setIsMatchingConditions(isToday && isWithinRange);
    setIsLocked(isWithin48Hours);
  }, []);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    navigate(`/expert/clients?client=${name}&id=${clientID}`);
  };

  const onCourseUndertaken = () => {
    navigate(`/expert/clients?client=${name}&id=${clientID}&tabIndex=2`);
  };

  const onChat = (clientId, name) => {
    navigate(`/expert/chat?clientID=${clientId}&clientName=${name}&type=redirect`);
  };

  const formattedDate = appointmentDate;

  const nextCard = {
    borderRadius: "10px",
    borderColor: "#50B3EF",
    outlineStyle: "solid !important",
    outlineWidth: "5px !important",
    outlineColor: "#73589b1A !important",
  };

  const defaultCard = {
    position: "relative",
    borderRadius: "10px",
  };

  const handleJoinCall = async () => {
    const headers = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
    };

    const payload = {
      user_id: currentUser?.UserId,
      appointment_id: appointmentID,
      join_status: "Joined",
      user_type: "Expert",
      current_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    //console.log("payload", payload);
    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/appointment/update-join-status`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      console.log("postResponse", postResponse);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Card
      variant="outlined"

      className={`AppointmentCard position-relative ${status == "Cancelled" && "Appointment--Cancelled"
        }`}
      sx={isMatchingConditions ? nextCard : defaultCard}
    > <div style={{

      position: "absolute",
      top: "25px",
      width: "6px",
      background: status == "Cancelled" ? "#F97066" : status == "Upcoming" ? "#A18DBE" : "#47CD89",
      height: "40px",
      borderRadius: "0px 10px 10px 0px"
    }}></div>
      <CardContent sx={{ pb: 2, borderRadius: 2 }}>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar src={img} sx={{ width: 56, height: 56 }} />

          <Box sx={{ flexGrow: 1 }}>
            <Typography className="d-flex justify-content-between align-items-center" variant="subtitle1" gutterBottom>
              {name}<Tooltip title={isLocked || status === "Completed" ? "Locked" : "Unlocked"}>
                {status !== "Cancelled" && (
                  isLocked || status === "Completed" ? <LockIcon /> : <LockOpenIcon />
                )}
              </Tooltip>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
                {clientType}
              </Typography>
              <Typography variant="body2" component="span">
                |
              </Typography>
              {status === "Cancelled" && (
                <Typography
                  className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
                  variant="body2" color="text.secondary"
                  sx={{ fontSize: '12px', color: '#D92D20', bgcolor: '#FEE4E2' }}
                >
                  Cancelled
                </Typography>
              )}
              {status === "Completed" && (
                <Typography
                  className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
                  variant="body2" color="text.secondary"
                  sx={{ fontSize: '12px', color: '#079455', bgcolor: '#DCFAE6' }}
                >
                  Completed
                </Typography>
              )}
              {status === "Upcoming" && (
                <Typography
                  className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
                  variant="body2" color="text.secondary"
                  sx={{ fontSize: '12px', color: '#654E89', bgcolor: '#ECE8F2' }}
                >
                  Upcoming
                </Typography>
              )}
            </Box>
          </Box>


        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={6} display="flex" alignItems="center">
            <CalendarMonthOutlinedIcon />
            <Typography sx={{ ml: 1 }}>{formattedDate}</Typography>
          </Grid>

          <Grid item xs={6} display="flex" alignItems="center">
            <AvTimerOutlinedIcon />
            <Typography sx={{ ml: 1 }}>{appointmentTime}</Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions className="Card--Actions" sx={{ justifyContent: 'space-between', padding: '8px 16px' }}>
        {(status === "Ongoing" || status === "Upcoming") && isMatchingConditions ? (
          <Button
            disableElevation
            variant="contained"
            size="large"
            fullWidth
            sx={{
              borderRadius: '20px', // Rounded but not too much
              bgcolor: 'var(--primary-base-color)',
              color: 'white',
              fontSize: '0.875rem', // 14px as in the default theme
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: 'var(--primary-base-color-darken)' // Slightly darker on hover
              }
            }}
            href={joinURL}
            target="_blank"
            onClick={handleJoinCall}
          >
            Join Now
          </Button>
        ) : (
          <>
            {status !== "Cancelled" && (
              <>
                <Tooltip title="View Details">
                  <Button className=" rounded-3 w-100" startIcon={<VisibilityIcon />} onClick={handleView}>

                    View
                  </Button>
                </Tooltip>
                <Tooltip title="Start Chat">
                  <Button className=" rounded-3 w-100" startIcon={<ChatBubbleOutlineOutlinedIcon />} onClick={() => onChat(clientID, name)}>

                    Chat
                  </Button>
                </Tooltip>
                <Tooltip title="More Options">
                  <IconButton className="  " onClick={handleClick}>

                    <MoreVert />
                  </IconButton>
                </Tooltip>

              </>
            )}

            <Menu
              id="demo-positioned-menu"
              MenuListProps={{ 'aria-labelledby': 'demo-positioned-button' }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {/* <MenuItem onClick={onAddNote}>Add Note</MenuItem> */}
              <Divider />
              {selectedTab !== "Past" && (
                <>
                  <MenuItem onClick={onReschedule}>Reschedule</MenuItem>
                  <Divider />
                </>
              )}
              {/* {selectedTab === "Past" && (
                <>
                  <MenuItem onClick={onPrescription}>Add Prescriptions</MenuItem>
                  <Divider />
                </>
              )} */}
              <MenuItem onClick={onCourseUndertaken}>Courses Undertaken</MenuItem>
              <Divider />
              {!isLocked && (status === "Open" || status === "Upcoming") && (
                <MenuItem sx={{ color: '#f57e8c' }} onClick={onCancel}>
                  Cancel Appointment
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default AppointmentCard;
