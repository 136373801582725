import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  CloseButton,
  Col,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./RegisterAsProfessional.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  OODOGetGender_V4Service,
  OODOGetId_Proofs_V4Service,
  usOnBoardingExpertProfileDetail,
  usOnBoardingSendRequestOTPService,
  usOnBoardingVerifyRequestOTPService,
  usOnBoarding_GetInfoRegisteredEmail,
  usOnBoarding_registrationStatus,
  usOnBoarding_GetState,
  usOnBoarding_GetSTDCode,
  usOnBoarding_GetTimeZone,
  usOnBoarding_GetCountry,
  usOnBoarding_updateProfilePicture,
} from "../core/_request";
import { LinearProgress, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";

const RegisterAsProfessionalProfileDetailTab = (props) => {
  const count_time = process.env.REACT_APP_OTP_TIME;
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
  const ALPHANUMERIC_DASH_REGEX = /^[0-9a-zA-Z. ]*$/;
  const ALPHANUMERICSomeSpecial_DASH_REGEX = /^[0-9a-zA-Z. /\&*-,]*$/;
  const phoneRegex = /^[0-9]{10}$/;

  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const idProofDoc = useRef();
  const inputRef = useRef(null);

  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState();
  const [idProofPercent, setIdProofPercent] = useState();
  const [idProofLoading, setIdProofLoading] = useState();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [call_codeList, setCall_CodeList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [id_ProofsList, setId_ProofsList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [validationMessage, setValidationMessage] = useState([]);
  const [email_verified, setEmail_verified] = useState(false);
  const [registeredData, setRegisteredData] = useState(false);
  const [statusData, setStatusData] = useState({});
  const handleClose = () => setModalShow(false);
  const [UserId, setUserId] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const [counter, setCounter] = useState(0);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [resendOtpSent, setResendOtpSent] = useState(false);
  const [idProofDocDetails, setIdProofDocDetails] = useState();
  const [profileImage, setProfileImage] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [otpAttempts, setOtpAttempts] = useState(0); // State to track OTP attempts
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const maxAttempts = 3; // Max allowed attempts

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    usOnBoarding_GetCountry().then((resp) =>
      setCountryList(resp.data.countries)
    );
    OODOGetGender_V4Service().then((resp) => setGenderList(resp.data.genders));
    OODOGetId_Proofs_V4Service().then((resp) =>
      setId_ProofsList(resp.data.id_proofs)
    );
  }, []);

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  });

  useEffect(() => {
    if (isLoading) {
      Save_Profile("nextSave");
    }
  });

  const onEmailChange = (value) => {
    setData({ ...data, Email: value });
    setValidationMessage([]);

    const pattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!pattern.test(value)) {
      const newValidationMessage = [
        ...validationMessage.filter((o) => o.key !== "Email"),
        { key: "Email", message: "Invalid Email address" },
      ];
      setValidationMessage(newValidationMessage);
    }
  };

  const onCountryChange = (value) => {
    var item = countryList.find((o) => o.id == value);
    if (item) {
      setData({ ...data, countryId: value, country: item.name });

      usOnBoarding_GetState(item?.code).then((resp) => {
        setStateList(resp?.data?.states);
      });

      usOnBoarding_GetSTDCode(item?.code).then((resp) => {
        setCall_CodeList(resp?.data);
      });

      var body = {
        code: item?.code,
      };
      usOnBoarding_GetTimeZone(body).then((resp) => {
        setTimezoneList(resp.data.timezones);
      });
    } else {
      setTimezoneList([]);
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const onStateChange = (value) => {
    var item = stateList.find((o) => o.id == value);
    if (item) {
      setData({ ...data, stateId: value, state: item.name });
    } else {
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const onTimezoneChange = (value) => {
    const item = timezoneList.find((o) => o.id == value);
    if (item) {
      setData({ ...data, timezoneId: value, timezoneName: item.name });
    } else {
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const openOtpPopup = () => {
    if (data && data.Email) {
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(data.Email)) {
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage?.filter((o) => o.key != "Email"),
            { key: "Email", message: "Invalid Email address" },
          ]
        );
        setValidationMessage(newValidationMessage);
        return;
      }
      setCounter(count_time);
      setOtpSent(true);
      var body = { email: data.Email };
      usOnBoardingSendRequestOTPService(body).then((resp) => {
        //console.log("SendRequestOTPService resp", resp);
        if (resp != null && resp.ResponseCode == 200) {
          setOtpSent(true);
        } else if (resp != null && resp.ResponseCode == 401) {
          //console.log("ResponseCode 401");
        }
      });

      setModalShow(true);
    } else {
      let newValidationMessage = Object.assign(
        [],
        [
          ...validationMessage?.filter((o) => o.key != "Email"),
          { key: "Email", message: "email id required" },
        ]
      );
      setValidationMessage(newValidationMessage);
    }
  };

  const onClickSubmitOTP = () => {
    // Check if OTP is empty or less than 4 characters
    if (!data?.OTP || data?.OTP.length < 4) {
      // Clear the "Incorrect OTP" message
      setValidationMessage(validationMessage.filter((o) => o.key !== "OTP"));
      enqueueSnackbar("Enter a valid OTP (minimum 4 digits)", {
        variant: "warning",
      });
      return false;
    } else {
      var req = {
        email: data.Email,
        otp: data?.OTP,
      };

      usOnBoardingVerifyRequestOTPService(req).then((resp) => {
        if (resp && resp.code === 200) {
          // OTP is correct
          setEmail_verified(true);
          setData({ ...data, email_verified: true });
          setUserId(resp?.data?.id);
          sessionStorage.setItem("Email_Verified_id", resp?.data?.id);
          Save_Profile("nextSave");

          var body = { user_id: UserId, email: data.Email, screen: null };
          usOnBoarding_GetInfoRegisteredEmail(body).then((resp) => {
            if (resp?.code === 200 && resp?.data?.profile?.email_verified) {
              setRegisteredData(true);
              setUserId(resp?.data?.id);
              sessionStorage.setItem(
                "User_Registered_Info",
                JSON.stringify(resp?.data)
              );
              setData({
                ...data,
                user_id: resp?.data?.id,
                first_name: resp?.data?.profile?.first_name,
                middle_name: resp?.data?.profile?.middle_name,
                last_name: resp?.data?.profile?.last_name,
                gender: resp?.data?.profile?.gender_id,
                image_link: resp?.data?.profile?.image_link,
                Email: resp?.data?.profile?.email,
                email_verified: resp?.data?.profile?.email_verified,
                id_proof_type_id: resp?.data?.profile?.id_proof_type_id,
                id_proof_link: resp?.data?.profile?.id_proof_link,
                dob: resp?.data?.profile?.dob,
                address_details_address: resp?.data?.profile?.address,
                address_details_landmark: resp?.data?.profile?.landmark,
                countryId: resp?.data?.profile?.country_id,
                stateId: resp?.data?.profile?.state_id,
                address_details_City: resp?.data?.profile?.city,
                address_details_time_zone: resp?.data?.profile?.timezone,
                address_details_zip_code: resp?.data?.profile?.zip,
                country_phone_code: resp?.data?.profile?.country_phone_code,
                address_details_mobile_number: resp?.data?.profile?.mobile,
              });
              enqueueSnackbar("Email ID already exists", {
                variant: "warning",
              });

              var body4 = { email: data.Email };
              usOnBoarding_registrationStatus(body4).then((resp) => {
                setStatusData(resp.data);
                if (
                  resp?.data.profile === true &&
                  resp?.data.professional === true &&
                  resp?.data.scheduling === true &&
                  resp?.data.payment === true
                ) {
                  props.onClick("thankYou");
                  props.onSuccess("summary");
                } else if (
                  resp?.data.profile === true &&
                  resp?.data.professional === true &&
                  resp?.data.scheduling === true
                ) {
                  props.onClick("payment");
                  props.onSuccess("payment");
                } else if (
                  resp?.data.profile === true &&
                  resp?.data.professional === true
                ) {
                  props.onClick("scheduling");
                  props.onSuccess("scheduling");
                } else if (resp.data.profile === true) {
                  props.onClick("professionalDetail");
                  props.onSuccess("professional");
                } else {
                  props.onClick("profile");
                }
              });
            }
          });
          setModalShow(false);
          setIsEmailVerified(true);

          // setOtpAttempts(0); // Reset OTP attempts on success
        } else {
          // OTP is incorrect, show the message and track attempts
          if (data?.OTP.length === 4) {
            let newValidationMessage = Object.assign(
              [],
              [
                ...validationMessage.filter((o) => o.key !== "OTP"),
                {
                  key: "OTP",
                  message: (
                    <span>
                      <ErrorOutlineIcon /> Incorrect OTP, Please try again
                    </span>
                  ),
                },
              ]
            );
            setValidationMessage(newValidationMessage);
          }

          // Increment the OTP attempt count
          // setOtpAttempts((prevAttempts) => prevAttempts + 1);

          // // Check if attempts have reached the limit
          // if (otpAttempts + 1 >= maxAttempts) {
          //   enqueueSnackbar("You have reached the maximum number of attempts", {
          //     variant: "error",
          //   });
          //   setModalShow(false); // Close the modal when max attempts are reached
          // }
        }
      });
    }
  };

  const onClickNext = () => {
    ValidateForm("saveNext");
    if (data?.user_id) {
      Save_Profile("saveNext");
    }
  };

  function ValidateForm(type) {
    if (
      data?.first_name === undefined ||
      data?.first_name === null ||
      data?.first_name === ""
    ) {
      enqueueSnackbar("Required First name", { variant: "warning" });
      return false;
    } else if (
      data?.last_name === undefined ||
      data?.last_name === null ||
      data?.last_name === ""
    ) {
      enqueueSnackbar("Required Last Name", { variant: "warning" });
      return false;
    } else if (
      data?.gender === undefined ||
      data?.gender === null ||
      data?.gender === ""
    ) {
      enqueueSnackbar("Required Gender", { variant: "warning" });
      return false;
    } else if (
      data?.Email === undefined ||
      data?.Email === null ||
      data?.Email === ""
    ) {
      enqueueSnackbar("Required EmailId", { variant: "warning" });
      return false;
    } else if (
      data?.profile_image === undefined ||
      data?.profile_image === null ||
      data?.profile_image === ""
    ) {
      enqueueSnackbar("Required Profile Photo", { variant: "warning" });
      return false;
    } else if (
      data?.id_proof_type_id === undefined ||
      data?.id_proof_type_id === null ||
      data?.id_proof_type_id === ""
    ) {
      enqueueSnackbar("Required Id Proof", { variant: "warning" });
      return false;
    } else if (
      data?.id_proof_link === undefined ||
      data?.id_proof_link === null ||
      data?.id_proof_link === ""
    ) {
      enqueueSnackbar("Required Id Proof Document", { variant: "warning" });
      return false;
    } else if (
      data?.dob === undefined ||
      data?.dob === null ||
      data?.dob === ""
    ) {
      enqueueSnackbar("Required Date of Birth", { variant: "warning" });
      return false;
    } else if (
      data?.address_details_address === undefined ||
      data?.address_details_address === null ||
      data?.address_details_address === ""
    ) {
      enqueueSnackbar("Required Address", { variant: "warning" });
      return false;
    } else if (
      data?.countryId === undefined ||
      data?.countryId === null ||
      data?.countryId === ""
    ) {
      enqueueSnackbar("Required Country", { variant: "warning" });
      return false;
    } else if (
      data?.stateId === undefined ||
      data?.stateId === null ||
      data?.stateId === ""
    ) {
      enqueueSnackbar("Required State", { variant: "warning" });
      return false;
    } else if (
      data?.address_details_City === undefined ||
      data?.address_details_City === null ||
      data?.address_details_City === ""
    ) {
      enqueueSnackbar("Required City", { variant: "warning" });
      return false;
    } else if (
      data?.address_details_time_zone === undefined ||
      data?.address_details_time_zone === null ||
      data?.address_details_time_zone === ""
    ) {
      enqueueSnackbar("Required Time Zone", { variant: "warning" });
      return false;
    } else if (
      data?.address_details_zip_code === undefined ||
      data?.address_details_zip_code === null ||
      data?.address_details_zip_code === ""
    ) {
      enqueueSnackbar("Required Zip Code", { variant: "warning" });
      return false;
    } else if (
      data?.address_details_zip_code?.length < 5 ||
      data?.address_details_zip_code?.length > 9
    ) {
      enqueueSnackbar("Required Valid Zip Code", { variant: "warning" });
      return false;
    } else if (
      data?.address_details_mobile_number === undefined ||
      data?.address_details_mobile_number === null ||
      data?.address_details_mobile_number === ""
    ) {
      enqueueSnackbar("Required Valid Mobile Number", { variant: "warning" });
      return false;
    } else if (data?.address_details_mobile_number?.length !== 10) {
      enqueueSnackbar("Required Valid Mobile Number", { variant: "warning" });
      return false;
    } else if (data?.address_details_mobile_number?.startsWith("0")) {
      enqueueSnackbar("Mobile Number Cannot start with '0'", {
        variant: "warning",
      });
      return false;
    } else if (
      data?.email_verified === undefined ||
      data?.email_verified === null ||
      data?.email_verified === false
    ) {
      openOtpPopup();
    } else {
      Save_Profile(type);
    }
  }

  const Save_Profile = (type) => {
    var userId =
      JSON.parse(sessionStorage.getItem("Email_Verified_id")) ??
      JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
      JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
    var body = {
      user_id: userId,
      first_name: data?.first_name,
      middle_name: data?.middle_name,
      last_name: data?.last_name,
      gender_id: data?.gender,
      email: data?.Email,
      email_verified: email_verified,
      image_link: data?.image_link,
      id_proof_type_id: data?.id_proof_type_id,
      id_proof_link: data?.id_proof_link,
      dob: data?.dob,
      address: data?.address_details_address,
      landmark: data?.address_details_landmark,
      country_id: data?.countryId,
      state_id: data?.stateId,
      city: data?.address_details_City,
      timezone: data?.address_details_time_zone,
      zip: data?.address_details_zip_code,
      country_phone_code: call_codeList?.phone_code ?? "+1",
      mobile: data?.address_details_mobile_number,
    };
    usOnBoardingExpertProfileDetail(body).then((resp) => {
      if (resp?.code === 200) {
        enqueueSnackbar("Professional Details Saved Successfully", {
          variant: "success",
        });
        sessionStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));

        props.onNext();
        props.onSuccess();
        setIsLoading(false);
      } else {
        enqueueSnackbar(resp?.data?.error ?? resp?.message, {
          variant: "error",
        });
      }
    });
  };

  const handleFileUpload = async (event) => {
    setIdProofLoading(true);

    const file = event.target.files[0];

    if (file.size > 5e6) {
      enqueueSnackbar("File Size Exceeds", { variant: "error" });
      setIdProofLoading(false);
      return false;
    }

    // Check for valid file types
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
    ];

    if (!validFileTypes.includes(file?.type)) {
      setIdProofLoading(false);
      enqueueSnackbar("Incorrect file format.", { variant: "error" });

      // Clear the file details if the format is incorrect
      setIdProofDocDetails(null);

      return false;
    }

    // Set file details if the format is valid
    setIdProofDocDetails(file);

    const dirName = moment().format("MMMM_YYYY");
    const storageRef = ref(storage, dirName + "/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        setIdProofPercent(percent);
      },
      (err) => {
        enqueueSnackbar(err, { variant: "error" });
      },
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          enqueueSnackbar("File uploaded Successfully.", {
            variant: "success",
          });
          setData({ ...data, id_proof_link: url });
          setIdProofLoading(false);
        });
      }
    );
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target?.form?.elements[next]?.focus();
      }
    }
  };

  const handleChange = (index, value) => {
    if (!isNaN(value) && value?.length === 1) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        setData({ ...data, OTP: newOtp.join("") });
        return newOtp;
      });

      if (index < 3 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (index > 0 && !otp[index]) {
        newOtp[index - 1] = "";
        inputRefs.current[index - 1].focus();
      } else {
        newOtp[index] = "";
      }

      setOtp(newOtp);
      setData((prevData) => ({
        ...prevData,
        OTP: newOtp.join(""),
      }));
    } else if (e.key === "Enter") {
      e.preventDefault();
      onClickSubmitOTP();
    } else if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const saveImage = (url) => {
    var user_id =
      JSON.parse(sessionStorage.getItem("Email_Verified_id")) ??
      JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
      JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
    if (user_id === undefined || user_id === null || user_id === "") {
      enqueueSnackbar("Please verify your email id first.", {
        variant: "warning",
      });
      return false;
    }
    var body = {
      user_id: user_id,
      image_link: url,
    };
    usOnBoarding_updateProfilePicture(body).then((resp) => {
      if (resp?.code === 200) {
        enqueueSnackbar("Profile Picture Updated Successfully", {
          variant: "success",
        });
        setData({ ...data, profile_image: url });
        sessionStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
        setProfileImage(true);
      } else {
        enqueueSnackbar(resp?.data?.error ?? resp?.message, {
          variant: "error",
        });
      }
    });
  };

  const handleImageUpload = async (event) => {
    setImageLoading(true);

    var file = event.target.files[0];

    if (file.size > 5e6) {
      enqueueSnackbar("File Size Exceeds 10MB", { variant: "error" });
      setImageLoading(false);
      return false;
    }

    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg"
    ) {
      var dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => enqueueSnackbar(err, { variant: "error" }),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setImageLoading(false);
            setData({ ...data, profile_image: url });
            setProfileImage(true);
            enqueueSnackbar("Profile Picture Updated Successfully", {
              variant: "success",
            });
          });
        }
      );
    } else {
      setImageLoading(false);
      enqueueSnackbar("Incorrect  file format.", { variant: "warning" });
    }
  };

  const removeDocument = () => {
    setIdProofDocDetails(null);
    enqueueSnackbar("File removed successfully!", {
      variant: "success",
    });
  };

  // console.log("data", data)

  return (
    <div>
      <div
        className="d-flex align-items-center gap-3 py-3 rounded-top px-3"
        style={{ background: "#DCD0EB" }}
      >
        <img src="/assets/img/Vector(21).png" alt="" />
        <p
          className="font-inter font-w500 text-black"
          style={{ color: "#21231E", fontSize: "24px" }}
        >
          Profile Details
        </p>
      </div>

      {isLoading && (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <CircularProgress color="#fff" size={20} />
        </div>
      )}

      <div className="my-4">
        <div className="mx-2 my-2">
          <div className="d-flex gap-2 mx-2 my-2 ">
            <TextField
              required
              type="text"
              inputProps={{ maxLength: 20 }}
              id="outlined-required"
              label="First Name"
              tabIndex="1"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.first_name}
              onChange={(e) => setData({ ...data, first_name: e.target.value })}
              className="w-75"
              onKeyDown={(event) => {
                if (
                  !ALPHA_NUMERIC_DASH_REGEX.test(event.key) &&
                  event.key !== "Backspace" &&
                  event.key !== "Tab"
                ) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pasteData = event.clipboardData.getData("text");
                if (!ALPHA_NUMERIC_DASH_REGEX.test(pasteData)) {
                  event.preventDefault();
                }
              }}
            />

            <TextField
              id="outlined"
              label="Middle Name (Optional)"
              tabIndex="2"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.middle_name}
              onChange={(e) =>
                setData({ ...data, middle_name: e.target.value })
              }
              className="w-75"
              inputProps={{ maxLength: 20 }}
              onKeyDown={(event) => {
                if (
                  !ALPHA_NUMERIC_DASH_REGEX.test(event.key) &&
                  event.key !== "Backspace" &&
                  event.key !== "Tab"
                ) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pasteData = event.clipboardData.getData("text");
                if (!ALPHA_NUMERIC_DASH_REGEX.test(pasteData)) {
                  event.preventDefault();
                }
              }}
            />

            <TextField
              required
              id="outlined"
              label="Last Name"
              tabIndex="3"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.last_name}
              onChange={(e) => setData({ ...data, last_name: e.target.value })}
              className="w-75"
              inputProps={{ maxLength: 20 }}
              onKeyDown={(event) => {
                if (
                  !ALPHA_NUMERIC_DASH_REGEX.test(event.key) &&
                  event.key !== "Backspace" &&
                  event.key !== "Tab"
                ) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pasteData = event.clipboardData.getData("text");
                if (!ALPHA_NUMERIC_DASH_REGEX.test(pasteData)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>

        <div className="d-flex gap-2 mx-3 my-3">
          <select
            required
            id="outlined-select-Gender"
            label="Gender"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.gender}
            className="col-4 select-option px-1 rounded-3"
            onChange={(e) => setData({ ...data, gender: e.target.value })}
          >
            <option>Select Gender *</option>
            {genderList?.map((item, index) => (
              <option
                key={index}
                selected={item.id === data?.gender}
                value={item.id}
              >
                {item.name}
              </option>
            ))}
          </select>

          <div className="d-flex flex-column col-4">
            <div className="d-flex align-items-center">
              <TextField
                required
                id="outlined"
                label="Email"
                type="email"
                tabIndex="5"
                disabled={isEmailVerified}
                InputLabelProps={registeredData && { shrink: true }}
                value={data?.Email}
                onChange={(e) => onEmailChange(e.target.value)}
                className="col-12"
              />
              {email_verified && (
                <span className="d-flex align-items-center gap-2 ms-3">
                  <img src="/assets/img/Vector(29).png" alt="Verified Icon" />
                  <p className="font-inter" style={{ color: "#0E0F0C" }}>
                    Verified
                  </p>
                </span>
              )}
            </div>

            <span
              className="error-message"
              style={{ color: "#dc3545", fontSize: "12px" }}
            >
              {validationMessage.filter((o) => o.key === "Email")[0]?.message}
            </span>
          </div>

          <Modal
            show={modalShow}
            size="md"
            aria-labelledby=""
            centered
            onHide={handleClose}
            className="professional-otp-popUp"
          >
            <Modal.Body className="">
              <div className="mb-3 d-flex flex-column align-items-center justify-content-around gap-3 py-3">
                <label
                  className="font-inter text-center lh-sm font-w600"
                  style={{ color: "#21231E", fontSize: "16px" }}
                >
                  <p className="font-inter text-black fs-4 font-w600 lh-lg">
                    OTP Verification
                  </p>
                  OTP (One Time Password) has been sent to email id{" "}
                  {data?.Email}
                </label>
                {/* <label
                  className="font-inter fs-6 px-4 text-center lh-base my-3"
                  style={{ color: "#21231E" }}
                >
                  Please verify your email id with the one-time passcode shared
                  on your email id {data?.Email}
                </label> */}
                <form className="otp-container d-flex align-items-center gap-3 gap-md-5 justify-content-center px-5">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="tel"
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      maxLength="1"
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </form>

                {resendOtpSent && counter !== 0 && (
                  <span
                    className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
                    style={{ color: "#00AD65" }}
                  >
                    {validationMessage.find((o) => o.key === "OTP")?.message}
                  </span>
                )}

                {!resendOtpSent &&
                  validationMessage.some((o) => o.key === "OTP") && (
                    <span
                      className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
                      style={{ color: "#dc3545" }}
                    >
                      {validationMessage.find((o) => o.key === "OTP")?.message}
                    </span>
                  )}

                <span className="error-message" style={{ color: "#dc3545" }}>
                  {" "}
                </span>
                <div className="d-flex align-items-center justify-content-between col-10 mx-auto">
                  <p
                    className="font-inter font-w600"
                    style={{
                      whiteSpace: "nowrap",
                      color: "#153300",
                      fontSize: "16px",
                    }}
                  >
                    Didn’t recieve code? <br />
                    {(counter === 0 && otpSent && (
                      <Link
                        onClick={() => openOtpPopup()}
                        className="text-decoration-none"
                        style={{
                          whiteSpace: "nowrap",
                          color: "blue",
                          fontSize: "14px",
                        }}
                      >
                        Resend Code
                      </Link>
                    )) || (
                      <span
                        className=""
                        style={{
                          whiteSpace: "nowrap",
                          color: "#6A6C6A",
                          fontSize: "14px",
                        }}
                      >
                        Next OTP in {counter} seconds
                      </span>
                    )}
                  </p>
                  <Button
                    disabled={
                      data?.OTP?.length < 4 ||
                      data?.OTP === undefined ||
                      otpAttempts >= 3
                    }
                    id="btn_Final_Submit"
                    className="font-inter px-4 border-0 rounded-3"
                    style={{
                      background: "#73589b",
                      height: "50px",
                      color: "#FFF",
                    }}
                    onClick={() => onClickSubmitOTP()}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <div className="d-flex flex-column align-items-left justify-content-center gap-2 px-4 py-2">
          {imageLoading ? (
            <div
              style={{
                height: 140,
                width: 140,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: "#4B4B4B" }} />
            </div>
          ) : (
            <>
              <img
                src={data?.profile_image ?? "/assets/img/noDP.png"}
                alt="Profile"
                className="rounded-3"
                style={{ height: 140, width: 140 }}
              />
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                ref={inputRef}
                onChange={handleImageUpload}
                className="d-none"
              />
              <Link
                style={{
                  color: "var(--Light-Base-Color-Primary, #153300)",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "130%",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                {profileImage ? "Change Photo" : "Upload Photo"}
              </Link>
              <div
                className="font-inter font-w500"
                style={{
                  color: "#6A6C6A",
                  fontSize: "12px",
                }}
              >
                Only .jpg, .jpeg, .png allowed up to 5mb
              </div>
            </>
          )}
        </div>

        <div className="px-2 my-2">
          <p
            className="font-inter font-w600 fs-6 px-3 my-2"
            style={{ color: "#21231E" }}
          >
            ID Proof
          </p>

          <div className="d-flex mx-2 gap-1">
            <select
              required
              id="outlined-select-currency"
              label="ID Proof"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.id_proof_type_id}
              className="col-4 select-option px-1 rounded-3"
              onChange={(e) =>
                setData({ ...data, id_proof_type_id: e.target.value })
              }
            >
              <option>Select ID Proof *</option>
              {id_ProofsList?.map((item, index) => (
                <option
                  key={index}
                  selected={item.id === data?.id_proof_type_id}
                  value={item.id}
                >
                  {item.name}
                </option>
              ))}
            </select>

            <Form.Group as={Col} className="position-relative mb-3 col-4">
              {(idProofLoading && (
                <>
                  <div className="file-upload">
                    <div className="upload-icon h-100 col-2">
                      <UploadFileRoundedIcon />
                    </div>
                    <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                      <CircularProgress sx={{ color: "#4B4B4B" }} />
                    </div>
                  </div>
                </>
              )) ||
                (idProofDocDetails ? (
                  <>
                    <div className="file-upload">
                      <div className="upload-icon h-100 col-2">
                        <UploadFileRoundedIcon />
                      </div>
                      <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                        <p
                          className="font-inter"
                          style={{
                            color: "#6A6C6A",
                            fontSize: 14,
                          }}
                        >
                          {idProofDocDetails.name &&
                          idProofDocDetails.name.length > 20
                            ? idProofDocDetails.name.substring(0, 20) + "..."
                            : idProofDocDetails.name}
                        </p>
                        <CloseIcon onClick={removeDocument} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Control
                      accept="image/*,.pdf"
                      type="file"
                      name="file"
                      className="d-none"
                      placeholder="Upload ID Proof"
                      onChange={handleFileUpload}
                      value={data?.file}
                      ref={idProofDoc}
                    />
                    <div
                      className="file-upload"
                      onClick={() => idProofDoc.current.click()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        maxWidth: "300px",
                        cursor: "pointer",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div className="upload-icon h-100 col-2">
                        <UploadFileRoundedIcon />
                      </div>
                      <div className="h-100 d-flex align-items-center w-100 ps-3">
                        <Typography variant="body2" noWrap>
                          {data?.file
                            ? data?.file?.length > 20
                              ? data.file.substring(0, 20) + "..."
                              : data.file
                            : "Upload ID Proof"}
                        </Typography>
                      </div>
                    </div>
                  </>
                ))}
              <Form.Label
                className="font-inter font-w500"
                style={{
                  color: "#6A6C6A",
                  fontSize: "14px",
                }}
              >
                Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
              </Form.Label>
              {data?.file && (
                <div>
                  {data?.file} <CloseButton />
                </div>
              )}
            </Form.Group>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label="Date of birth*"
                className="col-4"
                value={data?.dob ? dayjs(data.dob) : null} // initially empty if no dob
                onChange={(newValue) =>
                  setData({
                    ...data,
                    dob: moment(newValue.toDate()).format("YYYY-MM-DD"),
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Date"
                    inputProps={{ ...params.inputProps, readOnly: true }} // Disable typing
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="mx-2 my-2">
          <p
            className="font-inter font-w600 fs-6 px-3 my-2"
            style={{ color: "#21231E" }}
          >
            Personal Details
          </p>
          <div className="d-flex gap-2 mx-2">
            <TextField
              multiline
              maxRows={4}
              required
              id="outlined"
              label="Address"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.address_details_address}
              onChange={(e) =>
                setData({ ...data, address_details_address: e.target.value })
              }
              className="w-75"
            />

            <TextField
              id="outlined"
              label="Landmark (Optional)"
              onKeyDown={(event) => {
                if (!ALPHANUMERIC_DASH_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.address_details_landmark}
              onChange={(e) =>
                setData({ ...data, address_details_landmark: e.target.value })
              }
              className="w-75"
            />
            <select
              required
              id="outlined-select-Country"
              label="Country"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.countryId}
              className="col-4 select-option px-1 rounded-3"
              onChange={(e) => onCountryChange(e.target.value)}
            >
              <option>Select Country *</option>
              {countryList?.map((item, index) => (
                <option
                  key={index}
                  selected={item.id === data?.countryId}
                  value={item.id}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="d-flex gap-2 mx-3 my-3">
          <select
            required
            id="outlined-select-State"
            label="State"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.stateId}
            className="col-4 select-option px-1 rounded-3"
            onChange={(e) => onStateChange(e.target.value)}
          >
            <option>Select State *</option>
            {stateList?.map((item, index) => (
              <option
                key={index}
                selected={item.id === data?.stateId}
                value={item.id}
              >
                {item.name}
              </option>
            ))}
          </select>

          <TextField
            required
            id="outlined"
            label="City"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.address_details_City}
            onChange={(e) =>
              setData({ ...data, address_details_City: e.target.value })
            }
            className="w-75"
          />

          <select
            required
            id="outlined-select-Timezone"
            label="Time Zone"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.address_details_time_zone}
            className="col-4 select-option px-1 rounded-3"
            onChange={(e) =>
              setData({ ...data, address_details_time_zone: e.target.value })
            }
          >
            <option>Select Timezone *</option>
            {timezoneList?.map((item, index) => (
              <option
                key={index}
                selected={item.id === data?.address_details_time_zone}
                value={item.id}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex gap-2 mx-3 my-3">
          <TextField
            required
            id="outlined"
            label="Zip Code"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.address_details_zip_code}
            onChange={(e) =>
              setData({ ...data, address_details_zip_code: e.target.value })
            }
            className="col-4"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{ minLength: 6, maxLength: 9 }}
          />

          <Col xs="auto" className="col-4">
            <InputGroup className="mb-2" style={{ height: "56px" }}>
              <InputGroup.Text>
                {data?.country_phone_code ?? call_codeList?.phone_code ?? "+1"}
              </InputGroup.Text>
              <Form.Control
                required
                maxLength={10}
                minLength={10}
                inputProps={{ minLength: 10, maxLength: 10 }}
                type="tel"
                onChange={(e) =>
                  setData({
                    ...data,
                    address_details_mobile_number: e.target.value,
                  })
                }
                placeholder="Mobile*"
                InputLabelProps={registeredData && { shrink: true }}
                value={data?.address_details_mobile_number}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </InputGroup>
          </Col>
        </div>

        <ButtonGroup className="d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3">
          <Button
            onClick={onClickNext}
            className="rounded-2 border-0 saveButton"
            style={{
              backgroundColor: "#73589B",
              whiteSpace: "nowrap",
              color: "#FFF",
            }}
          >
            {/* {isEmailVerified ? "Save & Next" : "Verify Email"} */}
            Save & Next
          </Button>
        </ButtonGroup>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-end">
        <ToastContainer />
      </div>
    </div>
  );
};

export default RegisterAsProfessionalProfileDetailTab;
