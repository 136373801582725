import { useState, useEffect } from "react";

import Button from "@mui/material/Button";

//Table
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";

import moment from "moment";
import { Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";

const ViewWorksheetTableRow = ({
  id,
  name,
  status,
  img,
  country,
  assignedOn,
  completedOn,
  viewClick,
}) => {
  return (
    <TableRow
      id={id}
      className={`ClientDetails--TableRow`}
      sx={{
        backgroundColor: "#FFF",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell className="Table--Profile">
        <div className="d-flex align-items-center gap-2">
          <img
            src={img}
            width={45}
            height={45}
            style={{ borderRadius: "50%" }}
          />

          <div>
            <h6>{name}</h6>
            <span>{country}</span>
          </div>
        </div>
      </TableCell>

      <TableCell className="Table--AssignedOn">
        <div>
          <p>{moment(assignedOn).format("DD MMMM YYYY")}</p>
        </div>
      </TableCell>

      <TableCell className="Table--CompletedOn">
        {status == "Not Completed" ? (
          <p>Ongoing</p>
        ) : (
          <p>{moment(completedOn).format("DD MMMM YYYY")}</p>
        )}
      </TableCell>

      <TableCell className="">
        <Typography
          className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
          variant="body2" color="text.secondary"
          sx={{
            fontSize: '14px',
            color: status === "Not Completed" ? "#D92D20" : "#079455",
            bgcolor: status === "Not Completed" ? "#FEE4E2" : "#DCFAE6"
          }}
        >
          {status === "Not Completed" ? "Not Completed" : "Completed"}
        </Typography>
        {/* <span>+ Assign</span> */}
      </TableCell>

      <TableCell align="right">

        <Button

          variant="text"
          sx={{
            borderRadius: '20px', // Rounded but not too much
            bgcolor: '#f5f5f5',
            color: '#414141',
            fontSize: '14px', // 14px as in the default theme
            textTransform: 'capitalize',
            '&:hover': {
              background: "#f5f5f5"
            }
          }} className=" rounded-3 px-3" startIcon={<Visibility />}
          onClick={() =>
            viewClick(id, name, status, img, country, assignedOn, completedOn)
          }
        >

          View 
        </Button>

      </TableCell>
    </TableRow>
  );
};

export default ViewWorksheetTableRow;
