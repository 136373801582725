import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import moment from "moment";
import { Visibility } from "@mui/icons-material";

const WorksheetCard = ({
  id,
  assigned,
  name,
  updatedOn,
  avatars,
  viewActivity,
}) => {
  return (
    <Card
      className=""

      sx={{
        
        borderRadius: "10px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        transition: "box-shadow 0.3s ease",
        '&:hover': {
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        }
      }}
      id={id}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        {/* Name */}
        <Typography gutterBottom variant="h6" component="div" fontWeight="bold">
          {name}
        </Typography>

        {/* Updated Date */}
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          color="textSecondary"
        >
          {moment(updatedOn).format("DD MMMM YYYY")}
        </Typography>

        {/* Avatar Group */}
        {avatars?.length > 0 ? (
          <AvatarGroup className="d-flex justify-content-center" total={avatars?.length} max={4} sx={{ mt: 2 }}>
            {avatars?.map((image, index) => (
              <Avatar key={index} alt="Avatar" src={image} />
            ))}
          </AvatarGroup>
        ) : (
          <Typography
            variant="body2"
            sx={{ mt: 2, color: "#656565", fontSize: "14px" }}
          >
            No Client assigned
          </Typography>
        )}
      </CardContent>

      {/* Action Button */}
      <CardActions sx={{ justifyContent: 'center', padding: '16px 0' }}>
        <Button
          fullWidth
          variant="text"
          sx={{
            borderRadius: '20px', // Rounded but not too much
            bgcolor: '#f5f5f5',
            color: '#414141',
            fontSize: '14px', // 14px as in the default theme
            textTransform: 'capitalize',
            '&:hover': {
              background: "#f5f5f5"
            }
          }} className=" rounded-3" startIcon={<Visibility />} onClick={() => viewActivity(name, id)}>

          View Activity
        </Button>

      </CardActions>
    </Card>
  );
};

export default WorksheetCard;
