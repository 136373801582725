import React from 'react'
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { getCurrencyCode } from './core/commonFun';
import { handleBrokenImage } from '../lib/utils';


const ProfileCard = (props) => {
    const navigate = useNavigate();
    const { item, filters } = props;
    const onClick = (item) => {
        navigate(`/profession/${item?.RegisterYourselfAs}/${item?.Name}/${item.Id}`);
    }
    const ExpertisesArray = item?.Category?.split(',')
   // console.log(item, "item")
    const search = useLocation().search;
    const Profession_url = new URLSearchParams(search).get("Profession");
    const CurrencyCode = getCurrencyCode();

    return (
        <div className="mb-res-30 ani-fade-up mx-1 mx-md-0" data-ani-delay="0" style={{ border: "1px solid #E2E2E1", borderRadius: '15px', background: "#fff", cursor: "pointer", }}>
            <a onClick={() => onClick(item)} className="col-md-12" style={{ textDecoration: "none", }}>
                <div className='d-flex flex-column justify-content-between' style={{ minHeight: 300 }}>
                    {
                        filters === true
                        &&
                        <div className='d-flex align-items-center justify-content-center gap-2'
                            style={{ background: "#FAFAFA", borderRadius: "15px 15px 0px 0px" }}>
                            <img src='/assets/img/Icon.png' alt='' />
                            <p className='fs-6 font-inter' style={{ color: "#EDA02B" }}>Recommended</p>
                        </div>
                        ||
                        <div className='d-flex align-items-center justify-content-center gap-2'
                            style={{ background: "#FAFAFA", borderRadius: "15px 15px 0px 0px" }}>
                            {/* <img src='/assets/img/Icon.png' alt='' />
                            <p className='fs-6 font-inter' style={{ color: "#EDA02B" }}>Recommended</p> */}
                        </div>

                    }


                    <div className='d-flex py-3 px-3 gap-1'>
                        <div className='ms-2 mt-1 col-4'>
                            <img
                                src={item?.ProfileImage}
                                onError={(e) => {
                                    const newLink = handleBrokenImage(item?.ProfileImage);
                                    e.target.src = newLink
                                }}
                                alt=''
                                className='rounded-3 col-12 w-100'
                                style={{ display: "block", overflow: "hidden", aspectRatio: "1/1", objectFit: "cover", backgroundColor: "rgba(0, 0, 0, 0.11)", }}
                            />
                        </div>
                        <div className='ms-4 col-7'>
                            <h3 className='font-inter font-w600 fs-5 lh-base text-black'>{item?.Name.substring(0, 25)}</h3>
                            <p className='font-inter fs-6 lh-base text-black'>{item?.RegisterYourselfAs}</p>
                            <p className='font-inter fs-6 lh-base text-black'>Years of Exp. : {item?.Experience} yrs</p>
                            {
                                (item.RegisterYourselfAs === 'Therapist') && (item?.Qualifications !== undefined && item?.Qualifications !== "NA") &&
                                <div className='d-flex align-items-center'>
                                    <p className='font-inter fs-6 lh-base hideScrollbar text-black' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}>{item?.Qualifications}</p>
                                    {/* <span>...</span> */}
                                </div>
                            }
                            {/* <span className='font-inter fs-6 lh-base'>Speaks: <span className="font-w500 text-black">{item?.Languages?.length > 20 ? item?.Languages?.toString()?.slice(0, 20) + "..." : item?.Languages}</span></span><br /> */}
                            <p className='font-inter fs-6 lh-base text-black'><img src="/assets/img/usa-20.png" className='me-2' alt="" />{item?.Country}</p>
                        </div>
                    </div>

                    {/* <div className='py-3 px-3'>
                        <p className='d-flex flex-row gap-2 mx-0 my-2 card-font align-items-center hideScrollbar font-inter fs-6' style={{ overflowX: 'scroll' }}>
                           
                            {
                                ExpertisesArray.map((Expertise) => (
                                    <span className='font-inter font-w500 fs-6 rounded-2 py-1 px-2 ' style={{ border: "1px solid #E2E2E1", whiteSpace: "nowrap", }}>
                                        {Expertise}
                                    </span>
                                ))
                            }
                        </p>
                    </div> */}

                    <div className='d-flex flex-column gap-3 m-0 py-3 px-3' style={{ background: "#EDE1FF", borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                            {item?.NextAvailability ?
                                <div className='col-7 col-md-8'>
                                    <p className="font-inter fs-6 text-black">Next Availability</p>
                                    <h6 className="font-inter fs-6 text-black">
                                        {item?.NextAvailability} ({item?.TimeZone})</h6>
                                </div>
                                :
                                <div className='col-7 col-md-8'>
                                    <p className="font-inter fs-6" style={{ color: "#73589b", fontWeight: '500' }}>No Slots Available</p>
                                    <h6 className="font-inter fs-6 text-black">This Week</h6>
                                </div>}
                            <button id='professional_book_now' className='col-5 col-md-4 px-2 rounded-2 border-0 font-inter py-2 text-black' style={{ background: "var(--button-primary-color)", textAlign: 'center' }}>
                                <a href='' className='book-now-a-professional text-decoration-none fs-6 text-white'>Book Now</a>
                            </button>
                        </div>
                    </div>


                    {/* {
                        filters === true
                        &&
                        <div className='d-flex align-items-center gap-2 justify-content-center my-2'>
                            <img src='/assets/img/Mask group(9).png' alt='' />
                            <p className='font-inter fs-6 font-w500' style={{ color: "#73589b" }}>Addition 5% Discount for first clients</p>
                        </div>
                        ||
                        <div className='d-flex align-items-center gap-2 justify-content-center my-2'>
                            <img src='/assets/img/Mask group(9).png' alt='' />
                    <p className='font-inter fs-6 font-w500' style={{ color: "#73589b" }}>Addition 5% Discount for first clients</p>
                        </div>

                    } */}


                </div>
            </a>
        </div>
    )
}

export default ProfileCard