import axios from "axios";
import { Config } from "../../core/_request";
import jwtDecode from "jwt-decode";
//var jwtDecode = require('jwt-decode');

const headers = {
  "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
  "Content-Type": "application/json",
};

export const fetchAssessmentList = async () => {
  try {
    const response = await fetch(
      `https://odoo.unitedwecare.ca/new-uwc/list/test`,
      {
        method: "GET",
        headers,
      }
    );

    const postResponse = await response.json();

    if (postResponse?.ResponseCode == 200) {
      return postResponse?.data?.testList;
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const SendRequestOTPService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/CheckEmail_Send_OTP`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const CheckGoogleLogin = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/CheckSocialUser`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const validate_token = (token) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/sso/validate_token?token=${token}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const LoginByOTPService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/Check_Login`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const subscription_subscribe_odoo = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/subscribe`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      // if (resp.data)
      //     resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const RegistrationByOTPV4Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/Registration_V4`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getExpertListWithFilter_Web_V3Service = (req) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/popupservices/getExpertListWithFilter_Web_V3_Next_Appointment`,
      req,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const ExpertDetailsService = (id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/expertprofile/getExpertDetailsById?id=${id}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getExpertbyId_WebService = (id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/Auth/getExpertbyId_Web?UserId=${id}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const AppointmentTimeService = (ExpertID, Days) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/appointment/getAppointmentTime?ExpertID=${ExpertID}&Days=${Days}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const AddAppointmentUserService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/appointment/Add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const IndianpackagewithpriceService = (Id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/social_worker/indianpackagewithprice?ExpertId=${Id}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const getInstituteDiscountOODOService = (Amount) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/appointment/getInstituteDiscount_Odoo?Amount=${Amount}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const AddUserPackageService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/user_package/add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const UserDetailsService = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}api/Auth/GetUserbyId`, Config())
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const AddTransactionService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const UpdateTransactionService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/UpdateTransaction`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const FilterFieldsService = (Country) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/getpopupservices/getV2?Country=${Country}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const US_FilterFieldsService = (Country) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/us/get_Us_Onboarding_Category_filter`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getAppointment_Session = (Id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/appointment/getAppointment_Session?ExpertId=${Id}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const createTransactionforCourse_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/create`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const updateTransactionforCourse_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/update`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const CreateTransactionService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/create`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const UpdateTransactionV2Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/update`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const AppointmentService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/appointment/UpdateMeetlink_Event`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const UseCouponCodeService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/UseCouponCode`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const Get_Appointment_Detailsbyid = (Id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/appointment/get_Appointment_Detailsbyid?id=${Id}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOGetUserFeedV4Service = (
  UserId,
  ToUserId,
  categoryId,
  clubId,
  Action,
  dayType,
  PageNo,
  RecordPerPage,
  order
) => {
  ToUserId = ToUserId ?? "";
  categoryId = categoryId ?? "";
  clubId = clubId ?? "";
  order = order ?? "";
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/feed/${UserId}?s-user=${ToUserId}&category=${categoryId}&club=${clubId}&post-type=${Action}&day-time=${dayType}&page=${PageNo}&count=${RecordPerPage}&order=${order}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOGetUserProfileV4Service = (UserId, ToUserId) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/view-profile/${UserId}/${ToUserId}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOGetCountryListV4Service = () => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/country-list`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOGetState_ListV4Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/state/${body}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const OODOGetcall_code_ListV4Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/country/call-code/${body}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const OODOGetTimezoneV4Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/country-timezone`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOGetGender_V4Service = (body) => {
  return axios
    .post(`${process.env.REACT_APP_US_BASE_URL}new-uwc/gender`, {}, Config())
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const OODOGetId_Proofs_V4Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/id-proofs`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const OODOCreateUserV4Service = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/create-user/v2`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getCourseList_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/v2/community/course/list`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getCourseDetail_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/detail`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getCourseDashboardDetails_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/user/dashboard`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getDashboardAppointmentsHistory_WebService = (body) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/transaction/getMySubscriptionForCourseList`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getDashboardCourseList_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/active_courses`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      // console.log("resp", resp)
      if (resp?.ResponseCode === 200 || resp?.ResponseCode === 201) {
        //console.log("DATA", resp?.data)
        let response = [];
        if (resp?.data?.length > 0) {
          const programList = jwtDecode(resp.data);
          response = programList?.courses;
          // console.log("response", response)
        }

        return response;
      }
      // if (resp.data) resp.data = jwtDecode(resp.data);
      // return resp;
    });
};

export const getDashboardWorksheetList_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/worksheet/list`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getWorksheetData_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/worksheet/view`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const downloadWorksheetData_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/worksheet/download`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const uploadWorksheetData_WebService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/worksheet/upload`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOCoursePaymentService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/payment`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const OODOCourseAccessRequestService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/access-request`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const for_location = () => {
  return fetch(`${process.env.REACT_APP_GEO_LOCATION_URL}`, {
    // mode: 'no-cors',
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (resp) {
      return resp;
    })
    .catch((e) => console.error(e));
};

export const getCurrency = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}api/country/getCurrency`, Config())
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const submitCourseQuestions = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/community/course/option`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      // console.log("data", resp.data);
      return resp;
    });
};

export const getExpertRating = (id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/expertprofile/getExpertRatingUserSide?ExpertID=${id}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const Odoo_community_feed = async (body) => {
  if (body?.category) {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&category=${body?.category}`,
      {},
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = resp_1.data;
    return resp_1.data;
  } else if (body?.club) {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&club=${body?.club}`,
      {},
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = resp_1.data;
    return resp_1.data;
  } else if (body?.sUserId) {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}&s-user=${body?.sUserId}`,
      {},
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = resp_1.data;
    return resp_1.data;
  } else {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/public/feed?post-type=${body?.posttype}&page=${body?.page}&count=${body?.count}`,
      {},
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = resp_1.data;
    return resp_1.data;
  }
};

export const getEmailID = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/subscribe_news_letter/add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};
export const updatePhoneNumber = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/UpdateUserMobileNo_web`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const saveAppointmentOrder = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/appointment/save_Appointment_Order_Review `,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const course_clubs = () => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/club`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      //console.log("data", resp.data);
      return resp;
    });
};

export const course_web_lists = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/web_list_v2`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      //console.log("data", resp.data);
      return resp;
    });
};

export const get_course_details_web_by_Id = (id, body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/web_description/${id}`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const Course_Page_Tracking = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/appointment/Course_Page_Tracking`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const course_time_filter = () => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/filter/time`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      //console.log("data", resp.data);
      return resp;
    });
};

export const course_price_filter = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/community/course/filter/price`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      //console.log("data", resp.data);
      return resp;
    });
};

export const Course_CreateTr_Stripe = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/course_stripe_payment`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const course_CreateTr_manual_payment = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/transaction/course_manual_payment`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const usOnBoardingExpertProfileDetail = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/profile`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoardingSendRequestOTPService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc-jwt/registration/email`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const usOnBoardingVerifyRequestOTPService = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc-jwt/registration/email/verify`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

// export const usOnBoardingSendRequestOTPService = async (email) => {
//   try {
//     if (email !== null && email !== undefined) {
//       const resp = await axios.post(
//         `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}auth/otp/generate`,
//         { email },
//         Config()
//       );
//       // console.log("resp", resp);
//       return resp;
//     }
//   } catch (error) {
//     console.error("GenerateOTP: ", error);
//   }
// };

// export const usOnBoardingVerifyRequestOTPService = async (email, otp) => {
//   try {
//     if (email !== null && email !== undefined) {
//       const resp = await axios.post(
//         `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}auth/otp/verify`,
//         { email, otp },
//         Config()
//       );
//       console.log("resp", resp.data);
//       return resp.data;
//     }
//   } catch (error) {
//     console.error("VerifyOTP: ", error);
//   }
// };


export const usOnBoardingExpert_RegisterAs = () => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/registeras`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_Area_Interest = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/area-interest`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_Languages = () => {
  return axios
    .post(`${process.env.REACT_APP_US_BASE_URL}new-uwc/languages`, {}, Config())
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_LicenseState = () => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/license/state`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_LicenseType = () => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/license-type`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_TreatmentList = () => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/treatments`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_MentalDisorderTypeList = () => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/mental-disorders`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoardingExpertProfessionalDetails = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/professional`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_scheduling_timeslots = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/timeslots`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_Save_scheduling_timeslots = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/scheduling`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_Save_payment = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/payment`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_Disclaimer = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/disclaimer`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_GetInfoRegisteredEmail = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/professionals/get`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_updateProfilePicture = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/professionals/image`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_registrationStatus = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/registration/status`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_GetState = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/state/${body}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_GetSTDCode = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/country/call-code/${body}`,
      {},
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = resp.data;
      return resp;
    });
};

export const usOnBoarding_GetTimeZone = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/country-timezone`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      // if (resp.data)
      //     resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const usOnBoarding_GetCountry = (body) => {
  return axios
    .post(`${process.env.REACT_APP_US_BASE_URL}new-uwc/country`, {}, Config())
    .then((resp) => resp.data)
    .then((resp) => {
      // if (resp.data)
      //     resp.data = (resp.data);
      //     console.log("resp.dataresp.dataresp.data", resp.data)
      return resp;
    });
};

export const SubscriptionDiscount = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}uwc_institute/discount`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp.data;
    });
};

export const RateYourExpertAndFeedback = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/rateyourexpert/add`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      // if (resp.data)
      //     resp.data = (resp.data);
      //     console.log("resp.dataresp.dataresp.data", resp.data)
      return resp;
    });
};

export const isFirstTimeAppointment = () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/appointment/IsFirstTimeFreeDone`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const CheckReferralCode = (referralCode) => {
  return axios
    .get(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/verify-referral-code/${referralCode}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const UpdateCallJoinStatus = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/update-join-status`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const FeedbackFormCall = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-popup`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      // if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const GetGeoLocationByBrowser = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/extract/location`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const GeoLocationExpertStates = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/experts/states`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const updateDisclaimerUser = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/signup/disclaimer/update`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getDisclaimerUpdate = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/signup/disclaimer/get`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const CheckUserEmailIsRegister = (email) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/Auth/CheckUser_Emailid?Emailid=${email}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const FeedBackForm = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-form`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const FeedbackSubmit = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/feedback-form-data`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const getUserDetail = (body) => {
  return axios
    .post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/expert-feedback-details`,
      body,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      return resp;
    });
};

export const MySubscriptionService = () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/transaction/getMySubscription`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};

export const getInsuranceNetworkList = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/insurance-network-list`,
      {
        method: "GET",
        headers,
      }
    );

    const postResponse = await response.json();

    return postResponse?.data
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getTaxonomyCodesList = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_US_BASE_URL}new-uwc/taxonomy-codes-list`,
      {
        method: "GET",
        headers,
      }
    );

    const postResponse = await response.json();

    return postResponse?.data
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
