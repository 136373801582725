import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import AccessTimeIcon from "../../../assets/icons/clock-icon.svg";

import SessionComplete from "../modals/SessionCompleted";
import NotCompleted from "../modals/NotCompleted";
import Snacknbar from "./Snackbar";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

//Icons
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";

import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined"

import "./cards.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context";
import { Avatar, Button, Card, CardContent, Typography } from "@mui/material";
import styled from "styled-components";
import { CalenderSvg } from "../helpers/Svg";

const AllSessions = ({
  session,
  unMarkedSession,
  openModal,
  handleOpenModal,
  handleCloseModal,
  openModal1,
  handleOpenModal1,
  handleCloseModal1,
  loading,
  action
}) => {
  const [appointment_id, setAppointmentId] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("success");

  const navigate = useNavigate();
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleOpenSnackbar = () => {
    setOpen(true);
  };
  console.log("unMarkedSession", unMarkedSession)
  const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
    },
    borderRadius: theme.shape.borderRadius,
  }));

  const ActionButton = styled(Button)(({ theme }) => ({
    flex: 1,
    borderRadius: '20px', // More subtle curve
    height: '36px', // Slightly larger for better touch targets
    fontWeight: 600,
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }));
  return (
    <div>
      <Snacknbar
        message={message}
        open={open}
        status={status}
        handleClose={handleCloseSnackbar}
      ></Snacknbar>


      <div
        className=" row row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4 ">
        {!loading && unMarkedSession &&
          unMarkedSession.length > 0 &&
          unMarkedSession.map((data, index) => (
            <div className="p-2" key={index}>
              <Card sx={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", }} key={data.appointment_id} className=" rounded-3" >
                <CardContent className="p-3" sx={{ padding: '16px', borderRadius: "15px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  {/* Avatar and Client Info */}
                  <div className="d-flex justify-content-start align-items-center mb-3">
                    <Avatar
                      src={data.client_profile_picture}
                      alt="User"
                      sx={{ width: 50, height: 50, boxShadow: 1 }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '12px' }}>
                      <Typography variant="subtitle1" fontWeight={700}>
                        {data.client_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px', mt: '-4px' }}>
                        Follow-up
                      </Typography>
                    </div>
                  </div>

                  {/* Date and Time */}
                  <div className="d-flex justify-content-between align-items-center mt-2 px-1">
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', color: 'text.primary' }}
                    >
                      <CalendarMonthIcon sx={{ color: 'var(--primary-base-color)', mr: 1 }} />
                      {data.start_date}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', color: 'text.primary' }}
                    >
                      <AvTimerOutlinedIcon sx={{ color: 'var(--primary-base-color)', mr: 1 }} />
                      {data.start_time}
                    </Typography>
                  </div>

                  {/* Action Buttons */}
                  <div className="d-flex align-items-center gap-2 mt-4">
                    {/* <Button
                    variant="outlined"
                    color="success"
                    sx={{ flex: 1, borderRadius: '30px', height: '40px', textTransform: 'none' }}
                    onClick={() => {
                      handleOpenModal();
                      setAppointmentId(data.appointment_id);
                    }}
                  >
                    <DoneIcon sx={{ mr: 1 }} />
                    Confirm
                  </Button>

                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ flex: 1, borderRadius: '30px', height: '40px', textTransform: 'none' }}
                    onClick={() => {
                      handleOpenModal1();
                      setAppointmentId(data.appointment_id);
                    }}
                  >
                    <CloseIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button> */}

                    <Button
                      fullWidth
                      variant="text"
                      sx={{
                        borderRadius: '20px', // Rounded but not too much
                        bgcolor: '#f5f5f5',
                        color: '#414141',
                        fontSize: '14px', // 14px as in the default theme
                        textTransform: 'capitalize',
                        '&:hover': {
                          background: "#f5f5f5"
                        }
                      }} className=" rounded-3"
                      startIcon={<CloseIcon />}
                      onClick={() => {
                        handleOpenModal1();
                        setAppointmentId(data.appointment_id);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      variant="text"
                      sx={{
                        borderRadius: '20px', // Rounded but not too much
                        bgcolor: 'var(--primary-base-color)',
                        color: '#FFFFFF',
                        fontSize: '14px', // 14px as in the default theme
                        textTransform: 'capitalize',
                        '&:hover': {
                          background: "var(--primary-base-color-darken)"
                        }
                      }} className=" rounded-3"
                      startIcon={<DoneIcon />}
                      onClick={() => {
                        handleOpenModal();
                        setAppointmentId(data.appointment_id);
                      }}>

                      Confirm
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>



          ))}
        {!loading && (unMarkedSession?.length === 0) && (
          <div className="NoDataAvailable">
            {CalenderSvg}
            <p>
              No Sessions Found
            </p>
          </div>
        )}
        {!loading && !unMarkedSession && (
          <div className="NoDataAvailable">
            {CalenderSvg}
            <p>
              No Sessions Found
            </p>
          </div>
        )}

        <NotCompleted
          status={"write"}
          appointment_id={appointment_id}
          open={openModal1}
          handleClose={handleCloseModal1}
          setMessage={setMessage}
          handleOpenSnackbar={handleOpenSnackbar}
          setStatus={setStatus}
          action={action}
        />
        <SessionComplete
          status={"write"}
          appointment_id={appointment_id}
          open={openModal}
          handleClose={handleCloseModal}
          setMessage={setMessage}
          handleOpenSnackbar={handleOpenSnackbar}
          setStatus={setStatus}
          action={action}
        />
      </div>
    </div>
  );
};

export default AllSessions;
